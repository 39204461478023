import React from "react";
import logo from "./logo.jpeg";
import firebaseApp from "./Firebase";
import SideMenu from "./SideMenu";
import Helper from "./Helper";
import { Skeleton, Select, Table, Tag, Space, Divider, Col, List, Card, Typography, InputNumber, Input, Layout, Menu, Drawer, Button, Radio } from "antd";
import CartwinksFooter from "./CartwinksFooter"

const { SubMenu } = Menu;
const { Title, Text } = Typography;
const { Header, Footer, Sider, Content } = Layout;
const { Meta } = Card;
const { Option } = Select;

const deliveryStatus = { "0": "Door Delivery", "1": "I will pickup from the store" }
const orderStatus = { "0": "Order Placed", "1": "Packaging", "2": "Transit", "10": "Delivered" }
let urlRoute = {};
let userId = null;

const orderColumn = [
  {
    title: 'Date',
    dataIndex: 'created_at',
    key: 'date',
    render: (text) => (<Space size="middle">{text}</Space>)
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: 'Total',
    dataIndex: 'total',
    key: 'total',
  },
  {
    title: 'Items',
    key: 'items',
    dataIndex: 'items'
  }
];


class StoreOrders extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      storeOrderById: {}, 
      storeOrderByIdDiv: true
    }
  }



  fetchStoreOrders = (queryPromise) => {
    const storeOrderById = {};
    const addressIds = [];
    queryPromise.then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        storeOrderById[doc.id] = doc.data();
        storeOrderById[doc.id]["id"] = doc.id;
        if(storeOrderById[doc.id]["address_id"]!=undefined)
        {
          addressIds.push(storeOrderById[doc.id]["address_id"]);
        }
      });
      
      this.setState({ "storeOrderById": storeOrderById, "storeOrderByIdDiv":false });

      var queryAddressPromise = firebaseApp.findUserAddressByIds(addressIds);
      this.displayUserAddress(queryAddressPromise);
    });
  }

  displayUserAddress = (queryPromise) => {
    const storeOrderById = this.state.storeOrderById;
    const addressById = {};
    queryPromise.then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        addressById[doc.id] = doc.data();
      });
      
      for(var prop in storeOrderById)
      {
        let eachOrder = storeOrderById[prop];
        const userAddress = addressById[eachOrder["address_id"]];
        if(userAddress==undefined) continue;
        var userAddr = [];
        userAddr.push(userAddress["name"]);
        userAddr.push(userAddress["address_1"]);
        userAddr.push(userAddress["address_2"]);
        userAddr.push(userAddress["city"]);
        userAddr.push(userAddress["state"]);
        userAddr.push(userAddress["pincode"]);
        eachOrder["address"] = userAddr.join(", ");
        storeOrderById[prop] = eachOrder;
      }

      this.setState({ "storeOrderById": storeOrderById, "storeOrderByIdDiv":false });
    });
  }

  componentDidMount() {
    Helper.openLoginPage();
    userId = localStorage.getItem("currentUserId");
    urlRoute = Helper.getUrlRoute(window.location.pathname);
    console.log("url route");
    console.log(urlRoute);
    var queryPromise = firebaseApp.findStoreOrderByStoreId(urlRoute["myownstore"]);
    this.fetchStoreOrders(queryPromise);
  }



  showStoreCart = (e, orderId) => {
    const { history } = this.props;
    if (history) history.push(`/myownstore/${urlRoute["myownstore"]}/storeorder/${orderId}`);
  }


  deliveryOptionChangedByStore = (deliveryOption, userOrderId) => {
    console.log(deliveryOption, userOrderId);
    deliveryOption = (deliveryOption * 1);
    firebaseApp.updateUserOrder(userOrderId, { "delivery": deliveryOption, "status_at": firebaseApp.findServerTime() });
    //firebase.firestore().collection("user_orders").doc(userOrderId).update({"delivery": deliveryOption, "status_at": firebaseApp.findServerTime() });
  }



  deliveryStatusChangedByStore = (deliveryStatus, userOrderId) => {
    deliveryStatus = (deliveryStatus * 1);
    firebaseApp.updateUserOrder(userOrderId, { "status": deliveryStatus, "status_at": firebaseApp.findServerTime() });
    //firebase.firestore().collection("user_orders").doc(userOrderId).update({"status": deliveryStatus, "status_at": firebaseApp.findServerTime() });
    const orderStatusDoc = userOrderId + "_" + new Date();
    firebaseApp.saveUserOrderStatus(orderStatusDoc, { "status": deliveryStatus, "created_at": firebaseApp.findServerTime(), "user_id": userId });
    //firebase.firestore().collection("user_order_status").doc(orderStatusDoc).set({"status": deliveryStatus, "created_at": firebaseApp.findServerTime(), "user_id": userId });
  }

  render() {
    return (
      <div>
        <Layout style={{ backgroundColor: "#FFFFFF", minHeight: "100vh" }}>
          <Header style={{position: 'fixed', zIndex: 1, width: '100%', backgroundColor:"#003333", paddingLeft: 0 }}>
            <SideMenu />
          </Header>

          <Content style={{ backgroundColor: "#FFFFFF" }}>
            <Divider  className="divider"><i className="far fa-list-alt"></i> Order Details</Divider>
            <Skeleton loading={this.state.storeOrderByIdDiv} active avatar>
              {Object.values(this.state.storeOrderById).map((order, i) => (
                <Card hoverable style={{ marginTop: 50 }} key={`order-${i}`}>
                  <Title level={5}>Total Items: <span style={window.priceCss}>{order.items}</span>, Total Value: <span style={window.priceCss}>₹{order.total}</span> <Space size={15} /> </Title>
                  <div>
                    <Text type="secondary">Delivery Mode: <span style={{ fontWeight: "bold" }}>{deliveryStatus[order.delivery_type_id]}</span></Text> <br />
                    <Text type="secondary">Status: <span style={{ fontWeight: "bold" }}>{orderStatus[order.status]}</span></Text> <br />
                    <Text type="secondary">Date: {new Date(order.created_at.toDate()).toUTCString()}</Text><br />
                    <Text type="secondary">Show Details: <Typography.Link onClick={(e) => this.showStoreCart(e, order.id)}><i className="fas fa-luggage-cart"></i> See Details</Typography.Link></Text><br />
                    <Text type="secondary">Customer: {order.address}</Text>
                  </div>
                  <div style={{ marginTop: 15 }}><Title level={5}>Option &#x2003;
                    <Select showSearch defaultValue="Door Delivery" style={{ display: 'inline-block', width: 200 }} optionFilterProp="children"
                      onChange={(e) => this.deliveryOptionChangedByStore(e, order["order_id"])} onFocus={this.onFocus} onBlur={this.onBlur} onSearch={this.onSearch}
                      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                      <Option value="0">Door Delivery</Option>
                      <Option value="1">I will pickup from the store</Option>
                    </Select>
                  </Title>
                  </div>

                  <div><Title level={5}>Status &#x2003;
                    <Select showSearch defaultValue="1" style={{ display: 'inline-block', width: 200 }}
                      optionFilterProp="children" onChange={(e) => this.deliveryStatusChangedByStore(e, order["order_id"])}>
                      <Option value="0">Packaging</Option>
                      <Option value="1">In Transit</Option>
                      <Option value="10">Delivered</Option>
                    </Select>
                  </Title>
                  </div>
                </Card>
              ))}
            </Skeleton>
          </Content>

          <CartwinksFooter/>
        </Layout>
      </div>
    );
  }
}

export default StoreOrders;