import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Test from './Test';
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import AllProducts from './AllProducts';
import Cart from './Cart';
import StoreCart from './StoreCart';
import OrderedCart from './OrderedCart';
import UserOrders from './UserOrders'
import Stores from './Stores';
import StoreProducts from './StoreProducts';
import UserStores from './UserStores'
import OwnStores from './OwnStores'
import StoreOrders from './StoreOrders'
import PhoneAuth from './PhoneAuth'
import CcavenuePayment from './CcavenuePayment'
import Extras from "./Extras"
import FavoriteProducts from "./FavoriteProducts"

import { BrowserRouter, Route, Switch } from 'react-router-dom';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

window.priceCss = {fontSize:'15px', fontWeight:"bold", color:"#FF4500"};
window.quantity = {fontSize:'15px', fontWeight:"bold", color:"#0000FF"};
window.totalCostCss = {fontSize:'20px', fontWeight:"bold", color:"#FF4500"};
window.cartButton = { "background-color":"#1890ff" };
window.cartButtonClicked = {"background-color":"green"};
window.contentWidth = 250;
window.productLength = 50;

ReactDOM.render(
  <BrowserRouter basename="/">
        <Route exact path='/' component={AllProducts} />
        <Route exact path='/user/favorite/products' component={FavoriteProducts} />
        <Route path='/test' component={Test} />
        <Route path='/cart' component={Cart} />
        <Route path='/orders' component={UserOrders} />
        <Route path='/order/:orderId' component={OrderedCart} />
        <Route path='/mystores' component={UserStores} />
        <Switch>
          <Route path='/stores/:storeId' component={StoreProducts} />
          <Route path='/stores' component={Stores} />
        </Switch>
        <Route path='/myownstores' component={OwnStores}/>
        <Switch>
          <Route path='/myownstore/:storeId/storeorder/:orderId' component={StoreCart} />
          <Route path='/myownstore/:storeId/storeorders' component={StoreOrders} />
        </Switch>
        <Route path='/login' component={PhoneAuth} />
        <Route path='/handle-ccavenue-response' component={CcavenuePayment} />
        <Route path='/aboutus' component={Extras} />
        <Route path='/termsandcondition' component={Extras} />
        <Route path='/deliveryandshipment' component={Extras} />
        <Route path='/privacypolicy' component={Extras} />
        <Route path='/refundandcancellation' component={Extras} />
        <Route path='/contactus' component={Extras} />
        <Route path='/privacy' component={Extras} />
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
