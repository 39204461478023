import React from "react";
import firebaseApp from "./Firebase";
import SideMenu from "./SideMenu";
import { Divider, Row, Col, Card, Typography, Input, Layout, Button } from "antd";
import Helper from "./Helper";
import queryString from 'query-string';


const { Title, Text } = Typography;
const { Header, Content } = Layout;
const { Search } = Input;
let paymentStatus = null;
let cartwinks_payment_link = null;

let userInput = { "area_name": "", "pincode": "" };
let userId = null;

class CcavenuePayment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            urlValues: {}
        }
    }


    componentDidMount() {
        Helper.openLoginPage();
        const urlValues = queryString.parse(window.location.search);
        if (urlValues["status"] != undefined) {
            paymentStatus = urlValues["status"] + "";
            paymentStatus = paymentStatus.toLowerCase();
        }
        if (localStorage.getItem("cartwinks_payment_link") != null) {
            cartwinks_payment_link = localStorage.getItem("cartwinks_payment_link");
        }

        this.setState({ "urlValues": urlValues })
    }


    render() {
        return (
            <div>
                <Layout style={{ backgroundColor: "#FFFFFF", minHeight: "100vh" }}>
                    <Header style={{position: 'fixed', zIndex: 1, width: '100%', backgroundColor:"#003333", paddingLeft: 0 }}>
                        <SideMenu />
                    </Header>

                    <Content style={{ backgroundColor: "#FFFFFF" }}>

                        {(paymentStatus == "success") &&
                            <div>
                                <div className="intromessage" style={{marginTop:"20px", marginLeft:"20px", marginRight:"20px"}}>
                                    <p>Greetings from CartWinks!</p>
                                    <p>Your payment process status is <b>{paymentStatus}</b> </p>
                                    <p>Thank you for your valuable order! </p>
                                    <p>We will ensure orders are delivered at most and prompt. We will keep you update the process by Email/SMS.</p>
                                    <p>You can also check the status by clicking <a href={window.location.origin + "/orders"}>My Orders</a></p>
                                    <p>To place another order kindly click <br /> <a href={window.location.origin + "/orders"}>{window.location.origin}</a> </p>
                                </div>
                            </div>
                        }

                        {(paymentStatus != "success") &&
                            <div>
                                <div className="intromessage" style={{marginTop:"20px", marginLeft:"20px", marginRight:"20px"}}>
                                    <p>Greetings from CartWinks!</p>
                                    <p style={{ marginTop: "10px" }}>We notice you had tried to place an order on our Order Link but it was not completed. If you would like any assistance regarding your order / payment, do let us know how we can help you. To complete payment for your order pls click the payment link below:</p>
                                    <div style={{ background: "#fec435", "border": "1px solid #4f1804", paddingTop: "5px", borderRadius: "5px", fontWeight: "bold", marginTop: "10px", padding: "5px 5px 5px 5px" }}><a href={cartwinks_payment_link}>Click here to make payment</a></div>
                                    <p style={{ marginTop: "15px" }}>If you wish to place a fresh order pls click <br />
                                        <a href={window.location.origin}>{window.location.origin}</a></p>
                                    <p style={{ marginTop: "10px" }}>For any assistance do call us or Whatsapp us on <a href="tel://+91 93424 50052">+91 93424 50052</a> or email us on  <a href="mailto:contact@cartwinks.com">contact@cartwinks.com</a></p>
                                    <p style={{ marginTop: "10px" }}>Thanks &amp; regards<br />CartWinks Team</p>
                                </div>
                            </div>
                        }

                    </Content>

                </Layout>
            </div>
        );
    }

}

export default CcavenuePayment;
