import React from "react";
import SideMenu from "./SideMenu";
import { Modal, Tabs, Select, Form, Card, Typography, InputNumber, Input, Layout, Menu, Drawer, Button, Radio, Space } from "antd";
import firebaseApp from "./Firebase";
import { getAuth, signOut, RecaptchaVerifier, signInWithPhoneNumber, browserLocalPersistence, setPersistence, linkWithCredential, OAuthProvider, signInAnonymously, onAuthStateChanged } from "firebase/auth";
import CartwinksFooter from "./CartwinksFooter"

const { TabPane } = Tabs;
const { Title, Text } = Typography;
const { Option } = Select;
const { Header, Content } = Layout;
let userPhoneNumber = null;
let userPhoneOTP = null;
let verificationId = null;
var phoneNumberPattern = /^\d{10,15}$/;
let userAddress = {};
let cartWinksApp = null;
let userIsdCode = "";
let userIsdNumber = "91";
let isAnonymousUser = true;
let loginDiv = "block";
let logoutDiv = "none";
let userAddressesById = {}
let currentUserId = null;
let addressDefaults = { "name": "", "address_1": "", "address_2": "", "city": "", "state": "", "country": "", "email": "", "pincode": "" };
let confirmationResult = null;

const countryCodes = {
  "44": "GB",
  "1": "US",
  "213": "DZ",
  "376": "AD",
  "244": "AO",
  "1264": "AI",
  "1268": "AG",
  "54": "AR",
  "374": "AM",
  "297": "AW",
  "61": "AU",
  "43": "AT",
  "994": "AZ",
  "1242": "BS",
  "973": "BH",
  "880": "BD",
  "1246": "BB",
  "375": "BY",
  "32": "BE",
  "501": "BZ",
  "229": "BJ",
  "1441": "BM",
  "975": "BT",
  "591": "BO",
  "387": "BA",
  "267": "BW",
  "55": "BR",
  "673": "BN",
  "359": "BG",
  "226": "BF",
  "257": "BI",
  "855": "KH",
  "237": "CM",
  "1": "CA",
  "238": "CV",
  "1345": "KY",
  "236": "CF",
  "56": "CL",
  "86": "CN",
  "57": "CO",
  "269": "KM",
  "242": "CG",
  "682": "CK",
  "506": "CR",
  "385": "HR",
  "53": "CU",
  "90392": "CY",
  "357": "CY",
  "42": "CZ",
  "45": "DK",
  "253": "DJ",
  "1809": "DM",
  "1809": "DO",
  "593": "EC",
  "20": "EG",
  "503": "SV",
  "240": "GQ",
  "291": "ER",
  "372": "EE",
  "251": "ET",
  "500": "FK",
  "298": "FO",
  "679": "FJ",
  "358": "FI",
  "33": "FR",
  "594": "GF",
  "689": "PF",
  "241": "GA",
  "220": "GM",
  "7880": "GE",
  "49": "DE",
  "233": "GH",
  "350": "GI",
  "30": "GR",
  "299": "GL",
  "1473": "GD",
  "590": "GP",
  "671": "GU",
  "502": "GT",
  "224": "GN",
  "245": "GW",
  "592": "GY",
  "509": "HT",
  "504": "HN",
  "852": "HK",
  "36": "HU",
  "354": "IS",
  "91": "IN",
  "62": "ID",
  "98": "IR",
  "964": "IQ",
  "353": "IE",
  "972": "IL",
  "39": "IT",
  "1876": "JM",
  "81": "JP",
  "962": "JO",
  "7": "KZ",
  "254": "KE",
  "686": "KI",
  "850": "KP",
  "82": "KR",
  "965": "KW",
  "996": "KG",
  "856": "LA",
  "371": "LV",
  "961": "LB",
  "266": "LS",
  "231": "LR",
  "218": "LY",
  "417": "LI",
  "370": "LT",
  "352": "LU",
  "853": "MO",
  "389": "MK",
  "261": "MG",
  "265": "MW",
  "60": "MY",
  "960": "MV",
  "223": "ML",
  "356": "MT",
  "692": "MH",
  "596": "MQ",
  "222": "MR",
  "269": "YT",
  "52": "MX",
  "691": "FM",
  "373": "MD",
  "377": "MC",
  "976": "MN",
  "1664": "MS",
  "212": "MA",
  "258": "MZ",
  "95": "MN",
  "264": "NA",
  "674": "NR",
  "977": "NP",
  "31": "NL",
  "687": "NC",
  "64": "NZ",
  "505": "NI",
  "227": "NE",
  "234": "NG",
  "683": "NU",
  "672": "NF",
  "670": "NP",
  "47": "NO",
  "968": "OM",
  "680": "PW",
  "507": "PA",
  "675": "PG",
  "595": "PY",
  "51": "PE",
  "63": "PH",
  "48": "PL",
  "351": "PT",
  "1787": "PR",
  "974": "QA",
  "262": "RE",
  "40": "RO",
  "7": "RU",
  "250": "RW",
  "378": "SM",
  "239": "ST",
  "966": "SA",
  "221": "SN",
  "381": "CS",
  "248": "SC",
  "232": "SL",
  "65": "SG",
  "421": "SK",
  "386": "SI",
  "677": "SB",
  "252": "SO",
  "27": "ZA",
  "34": "ES",
  "94": "LK",
  "290": "SH",
  "1869": "KN",
  "1758": "SC",
  "249": "SD",
  "597": "SR",
  "268": "SZ",
  "46": "SE",
  "41": "CH",
  "963": "SI",
  "886": "TW",
  "7": "TJ",
  "66": "TH",
  "228": "TG",
  "676": "TO",
  "1868": "TT",
  "216": "TN",
  "90": "TR",
  "7": "TM",
  "993": "TM",
  "1649": "TC",
  "688": "TV",
  "256": "UG",
  "44": "GB",
  "380": "UA",
  "971": "AE",
  "598": "UY",
  "1": "US",
  "7": "UZ",
  "678": "VU",
  "379": "VA",
  "58": "VE",
  "84": "VN",
  "84": "VG",
  "84": "VI",
  "681": "WF",
  "969": "YE",
  "967": "YE",
  "260": "ZM",
  "263": "ZW"
};


const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 8 },
  initialvalues: { yourDesiredFormInputName: true }
}
const tailLayout = {
  wrapperCol: { offset: 8, span: 8 },
}

class PhoneAuth extends React.Component {

  addressFormRef = React.createRef();


  constructor(props) {
    super(props);

    cartWinksApp = this;

    this.state = {
      products: [],
      productsById: {},
      placement: "left",
      collapsed: false,
      loginDiv: 'block',
      captchaDiv: "block",
      logoutDiv: 'none',
      showOtpDiv: 'none',
      savedMessage: false,
      userAddress: {},
      otpReceivedDiv: 'none',
      validPhoneNumber: false,
      verifyUserOtp: false,
      userAddressesById: {},
      userAddressesDiv: "none",
      editUserAddressDiv: "none",
      modalAskSignin: false
    }
  }
  onSignInSubmit() {
    console.log("onSignInSubmit: onSignInSubmit");
  }

  componentDidMount() {
    const firebaseAuth = getAuth();
    setPersistence(firebaseAuth, browserLocalPersistence);
    window.recaptchaVerifier = new RecaptchaVerifier("recaptcha-container", { size: "normal", 'callback': (response) => { this.onSignInSubmit(); }, 'expired-callback': () => { console.log("captcha timeout. Please retry!"); } }, firebaseAuth);
    userAddress = localStorage.getItem("cartwinksaddress");
    if (userAddress != null) {
      userAddress = JSON.parse(userAddress);
    }
    if ((userAddress == null) || (userAddress == "null")) {
      userAddress = {};
    }

    this.pullUserAddress();


    onAuthStateChanged(firebaseAuth, (currentUser) => {
      console.log(currentUser);
      let userTempId = "cwu" + new Date().getTime();
      currentUserId = userTempId;
      if (currentUser) {
        userTempId = currentUser.uid;
        currentUserId = userTempId;
        userAddress["uid"] = userTempId;
        localStorage.setItem("currentUserId", userTempId);
        localStorage.setItem("currentUserAnonymous", currentUser.isAnonymous);
        isAnonymousUser = currentUser.isAnonymous;
        if (isAnonymousUser == true) {
          console.log("Authorized anonymous user");
          loginDiv = "block"
        }
        else {
          console.log("Authorized user");
          loginDiv = "none";
          logoutDiv = "block";
        }
        cartWinksApp.setState({ "uid": userTempId, "loginDiv": loginDiv, "userAddress": userAddress, "logoutDiv": logoutDiv });
        return;
      }

      if ((localStorage.getItem("currentUserId") == null) || (localStorage.getItem("currentUserId") == undefined)) {
        localStorage.setItem("currentUserId", userTempId);
      }
      else {
        userTempId = localStorage.getItem("currentUserId");
      }

      userAddress["uid"] = userTempId;
      const firebaseAuth = getAuth();
      setPersistence(firebaseAuth, browserLocalPersistence);
      window.recaptchaVerifier = new RecaptchaVerifier("recaptcha-container", { size: "normal", 'callback': (response) => { this.onSignInSubmit(); }, 'expired-callback': () => { console.log("captcha timeout. Please retry!"); } }, firebaseAuth);
      cartWinksApp.setState({ "uid": userTempId, "loginDiv": loginDiv, "userAddress": userAddress, "logoutDiv": logoutDiv });
      console.log("Unauthorized user");
    });

    console.log(userAddress);
  }



  getUserPhoneNumber = (e) => {
    userPhoneNumber = e.target.value;
    userPhoneNumber = userPhoneNumber.replace(/ /g, "");
    console.log(userPhoneNumber);
  }



  getUserPhoneOTP = (e) => {
    userPhoneOTP = e.target.value;
    console.log(userPhoneOTP);
    console.log(userPhoneOTP.length);
    if (userPhoneOTP.length > 5) {
      this.verifyWithOTP();
    }
  }



  userAddressInput = (e, fieldName) => {
    userAddress[fieldName] = e.target.value;
    console.log(userAddress);
    this.setState({ "savedMessage": false })
  }



  displayUserAddress = (queryPromise) => {
    userAddressesById = {};
    queryPromise.then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        userAddressesById[doc.id] = doc.data();
        userAddressesById[doc.id]["id"] = doc.id;
      });
      this.setState({ "userAddressesDiv": "block", "userAddressesById": userAddressesById });
    });
    console.log(userAddressesById);
  };




  pullUserAddress = () => {
    if ((localStorage.getItem("currentUserId") == null) || (localStorage.getItem("currentUserId") == undefined)) {
      return;
    }
    const currentUserId = localStorage.getItem("currentUserId");
    console.log(currentUserId);
    var queryPromise = firebaseApp.findUserAddress(currentUserId);
    this.displayUserAddress(queryPromise);
  }



  editUserAddressById = (addressId) => {
    if (isAnonymousUser) {
      this.setState({ "modalAskSignin": true });
      return;
    }
    if (userAddressesById[addressId] == undefined) {
      userAddress = addressDefaults;
    }
    else {
      userAddress = userAddressesById[addressId];
    }

    console.log(userAddress);

    this.addressFormRef.current.setFieldsValue(userAddress);

    this.setState({ "userAddress": userAddress, "editUserAddressDiv": "block" });
  }

  deleteUserAddressById = (addressId) => {
    delete userAddressesById[addressId];
    firebaseApp.deleteUserAddressById(addressId);
    this.setState({ "userAddressesDiv": "block", "userAddressesById": userAddressesById });

  }



  handleOk = () => {
    this.setState({ "modalAskSignin": false });
  }

  handleCancel = () => {
    this.setState({ "modalAskSignin": false });
  }

  saveUserDetails = () => {
    localStorage.setItem("cartwinksaddress", JSON.stringify(userAddress));
    currentUserId = localStorage.getItem("currentUserId")
    if (userAddress["doc_id"] == undefined) {
      userAddress["doc_id"] = currentUserId + "_" + new Date().getTime();
      userAddress["created_at"] = firebaseApp.findServerTime();
    }
    else {
      userAddress["updated_at"] = firebaseApp.findServerTime();
    }
    userAddress["user_id"] = currentUserId;
    userAddress["active"] = 1;
    console.log(userAddress);
    localStorage.setItem("cartwinksaddress", JSON.stringify(userAddress));
    firebaseApp.saveUserAddress( userAddress["doc_id"], userAddress );
    this.setState({ "savedMessage": true });
    setTimeout(function(){ window.location.href = "/login"; }, 2000);
  }




  getUserAuth = () => {
    const firebaseAuth = getAuth();
    setPersistence(firebaseAuth, browserLocalPersistence);
    onAuthStateChanged(firebaseAuth, (currentUser) => {
      if (currentUser) {
        const uid = currentUser.uid;
        isAnonymousUser = currentUser.isAnonymous;
        console.log("User is signed in");
      } else {
        console.log("User is signed out");
      }
    });
  }


  otpRequestToServer() {
    console.log("otpRequestToServer....");
    if (!phoneNumberPattern.test(userPhoneNumber)) {
      console.log("Invalid phone number....");
      return;
    }
    this.setState({ "validPhoneNumber": true});
    //firebase.auth().settings.appVerificationDisabledForTesting = true;
    const appVerifier = window.recaptchaVerifier;
    const userPhoneWithIsd = "+" + userIsdNumber + userPhoneNumber;
    console.log(userPhoneWithIsd);
    const firebaseAuth = getAuth();
    setPersistence(firebaseAuth, browserLocalPersistence);
    signInWithPhoneNumber(firebaseAuth, userPhoneWithIsd, appVerifier)
      .then(confirmResult => {
        console.log(confirmResult);
        confirmationResult = confirmResult
        verificationId = confirmResult.verificationId;
        this.setState({ "showOtpDiv": "block", "otpReceivedDiv": "block", "captchaDiv":"block", "loginDiv": loginDiv, "validPhoneNumber": true });
      })
      .catch(error => {
        console.log("Error while sighin with phone number");
        console.log(error);
        localStorage.removeItem("currentUserId");
      });
  }

  verifyWithOTP = () => {
    console.log("Validating the OTP....");
    const firebaseAuth = getAuth();
    setPersistence(firebaseAuth, browserLocalPersistence);
    confirmationResult.confirm(userPhoneOTP).then((result) => {
      const user = result.user;
      console.log(user);
      loginDiv = "none";
      logoutDiv = "block";
      console.log("Anonymous account successfully upgraded", user);
      localStorage.setItem("currentUserId", user.uid);
      this.setState({ "verifyUserOtp": true });
      //window.location.href = "/";

      console.log("verifyWithOTP....");
      firebaseApp.assignAnonymousUserWithAuthUser(localStorage.getItem("currentAnonymousUserId"), user.uid);
      localStorage.removeItem("currentAnonymousUserId");
      
      const credential = OAuthProvider.credentialFromResult(result);
      linkWithCredential(user.currentUser, credential)
      .then((usercred) => {
        const linkedUser = usercred.user;
        localStorage.setItem("currentUserId", linkedUser["uid"]);
        console.log("Anonymous account linked upgraded", linkedUser);
        window.location.href = "/";
        setTimeout(function(){ window.location.href = "/"; }, 2000);
      }).catch((error) => {
        console.log("Error upgrading anonymous account", error);
      });
      
    }).catch((error) => {
      console.log("Error upgrading anonymous account", error);
    });

  }

  signOutTheUser = () => {
    signOut(getAuth()).then(() => {
      window.location.href = window.location.href;
    }).catch((error) => {
      window.location.href = window.location.href;
    });
  }

  userIsdCodeChanged = (userCountryCode) => {
    userIsdNumber = userCountryCode;
    userIsdCode = countryCodes[userCountryCode];
    console.log(userCountryCode);
  }


  handleClick = e => {
    e.preventDefault();
    this.props.form.setFieldsValue({
      name: "Saravan"
    });
  };

  tabsClicked = (key) => {
    if (key == "user-address") {
      console.log(key);
      this.pullUserAddress(key);
    }
    this.setState({ "editUserAddressDiv": "none" });
  };

  render() {

    return (
      <div>
        <Layout style={{ backgroundColor: "#FFFFFF", padding: '0px 0px 0px 0px', minHeight: "100vh" }}>
          <Header style={{position: 'fixed', zIndex: 1, width: '100%', backgroundColor:"#003333", paddingLeft: 0 }}>
            <SideMenu />
          </Header>

          <Content style={{ display: this.state.loginDiv, marginTop: 20, padding: '0px 0px 0px 0px', width: '100%' }}>
            <center>
              <Card style={{ width: "300px" }}>
                <div>
                  <Select style={{ width: "250px" }} defaultValue="91" size='large' showSearch
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    onSelect={(e) => this.userIsdCodeChanged(e)} onChange={(e) => this.userIsdCodeChanged(e)}>
                    <Option value="44">UK (+44)</Option>
                    <Option value="1">USA (+1)</Option>
                    <Option value="213">Algeria (+213)</Option>
                    <Option value="376">Andorra (+376)</Option>
                    <Option value="244">Angola (+244)</Option>
                    <Option value="1264">Anguilla (+1264)</Option>
                    <Option value="1268">Antigua &amp; Barbuda (+1268)</Option>
                    <Option value="54">Argentina (+54)</Option>
                    <Option value="374">Armenia (+374)</Option>
                    <Option value="297">Aruba (+297)</Option>
                    <Option value="61">Australia (+61)</Option>
                    <Option value="43">Austria (+43)</Option>
                    <Option value="994">Azerbaijan (+994)</Option>
                    <Option value="1242">Bahamas (+1242)</Option>
                    <Option value="973">Bahrain (+973)</Option>
                    <Option value="880">Bangladesh (+880)</Option>
                    <Option value="1246">Barbados (+1246)</Option>
                    <Option value="375">Belarus (+375)</Option>
                    <Option value="32">Belgium (+32)</Option>
                    <Option value="501">Belize (+501)</Option>
                    <Option value="229">Benin (+229)</Option>
                    <Option value="1441">Bermuda (+1441)</Option>
                    <Option value="975">Bhutan (+975)</Option>
                    <Option value="591">Bolivia (+591)</Option>
                    <Option value="387">Bosnia Herzegovina (+387)</Option>
                    <Option value="267">Botswana (+267)</Option>
                    <Option value="55">Brazil (+55)</Option>
                    <Option value="673">Brunei (+673)</Option>
                    <Option value="359">Bulgaria (+359)</Option>
                    <Option value="226">Burkina Faso (+226)</Option>
                    <Option value="257">Burundi (+257)</Option>
                    <Option value="855">Cambodia (+855)</Option>
                    <Option value="237">Cameroon (+237)</Option>
                    <Option value="1">Canada (+1)</Option>
                    <Option value="238">Cape Verde Islands (+238)</Option>
                    <Option value="1345">Cayman Islands (+1345)</Option>
                    <Option value="236">Central African Republic (+236)</Option>
                    <Option value="56">Chile (+56)</Option>
                    <Option value="86">China (+86)</Option>
                    <Option value="57">Colombia (+57)</Option>
                    <Option value="269">Comoros (+269)</Option>
                    <Option value="242">Congo (+242)</Option>
                    <Option value="682">Cook Islands (+682)</Option>
                    <Option value="506">Costa Rica (+506)</Option>
                    <Option value="385">Croatia (+385)</Option>
                    <Option value="53">Cuba (+53)</Option>
                    <Option value="90392">Cyprus North (+90392)</Option>
                    <Option value="357">Cyprus South (+357)</Option>
                    <Option value="42">Czech Republic (+42)</Option>
                    <Option value="45">Denmark (+45)</Option>
                    <Option value="253">Djibouti (+253)</Option>
                    <Option value="1809">Dominica (+1809)</Option>
                    <Option value="1809">Dominican Republic (+1809)</Option>
                    <Option value="593">Ecuador (+593)</Option>
                    <Option value="20">Egypt (+20)</Option>
                    <Option value="503">El Salvador (+503)</Option>
                    <Option value="240">Equatorial Guinea (+240)</Option>
                    <Option value="291">Eritrea (+291)</Option>
                    <Option value="372">Estonia (+372)</Option>
                    <Option value="251">Ethiopia (+251)</Option>
                    <Option value="500">Falkland Islands (+500)</Option>
                    <Option value="298">Faroe Islands (+298)</Option>
                    <Option value="679">Fiji (+679)</Option>
                    <Option value="358">Finland (+358)</Option>
                    <Option value="33">France (+33)</Option>
                    <Option value="594">French Guiana (+594)</Option>
                    <Option value="689">French Polynesia (+689)</Option>
                    <Option value="241">Gabon (+241)</Option>
                    <Option value="220">Gambia (+220)</Option>
                    <Option value="7880">Georgia (+7880)</Option>
                    <Option value="49">Germany (+49)</Option>
                    <Option value="233">Ghana (+233)</Option>
                    <Option value="350">Gibraltar (+350)</Option>
                    <Option value="30">Greece (+30)</Option>
                    <Option value="299">Greenland (+299)</Option>
                    <Option value="1473">Grenada (+1473)</Option>
                    <Option value="590">Guadeloupe (+590)</Option>
                    <Option value="671">Guam (+671)</Option>
                    <Option value="502">Guatemala (+502)</Option>
                    <Option value="224">Guinea (+224)</Option>
                    <Option value="245">Guinea - Bissau (+245)</Option>
                    <Option value="592">Guyana (+592)</Option>
                    <Option value="509">Haiti (+509)</Option>
                    <Option value="504">Honduras (+504)</Option>
                    <Option value="852">Hong Kong (+852)</Option>
                    <Option value="36">Hungary (+36)</Option>
                    <Option value="354">Iceland (+354)</Option>
                    <Option value="91" Selected>India (+91)</Option>
                    <Option value="62">Indonesia (+62)</Option>
                    <Option value="98">Iran (+98)</Option>
                    <Option value="964">Iraq (+964)</Option>
                    <Option value="353">Ireland (+353)</Option>
                    <Option value="972">Israel (+972)</Option>
                    <Option value="39">Italy (+39)</Option>
                    <Option value="1876">Jamaica (+1876)</Option>
                    <Option value="81">Japan (+81)</Option>
                    <Option value="962">Jordan (+962)</Option>
                    <Option value="7">Kazakhstan (+7)</Option>
                    <Option value="254">Kenya (+254)</Option>
                    <Option value="686">Kiribati (+686)</Option>
                    <Option value="850">Korea North (+850)</Option>
                    <Option value="82">Korea South (+82)</Option>
                    <Option value="965">Kuwait (+965)</Option>
                    <Option value="996">Kyrgyzstan (+996)</Option>
                    <Option value="856">Laos (+856)</Option>
                    <Option value="371">Latvia (+371)</Option>
                    <Option value="961">Lebanon (+961)</Option>
                    <Option value="266">Lesotho (+266)</Option>
                    <Option value="231">Liberia (+231)</Option>
                    <Option value="218">Libya (+218)</Option>
                    <Option value="417">Liechtenstein (+417)</Option>
                    <Option value="370">Lithuania (+370)</Option>
                    <Option value="352">Luxembourg (+352)</Option>
                    <Option value="853">Macao (+853)</Option>
                    <Option value="389">Macedonia (+389)</Option>
                    <Option value="261">Madagascar (+261)</Option>
                    <Option value="265">Malawi (+265)</Option>
                    <Option value="60">Malaysia (+60)</Option>
                    <Option value="960">Maldives (+960)</Option>
                    <Option value="223">Mali (+223)</Option>
                    <Option value="356">Malta (+356)</Option>
                    <Option value="692">Marshall Islands (+692)</Option>
                    <Option value="596">Martinique (+596)</Option>
                    <Option value="222">Mauritania (+222)</Option>
                    <Option value="269">Mayotte (+269)</Option>
                    <Option value="52">Mexico (+52)</Option>
                    <Option value="691">Micronesia (+691)</Option>
                    <Option value="373">Moldova (+373)</Option>
                    <Option value="377">Monaco (+377)</Option>
                    <Option value="976">Mongolia (+976)</Option>
                    <Option value="1664">Montserrat (+1664)</Option>
                    <Option value="212">Morocco (+212)</Option>
                    <Option value="258">Mozambique (+258)</Option>
                    <Option value="95">Myanmar (+95)</Option>
                    <Option value="264">Namibia (+264)</Option>
                    <Option value="674">Nauru (+674)</Option>
                    <Option value="977">Nepal (+977)</Option>
                    <Option value="31">Netherlands (+31)</Option>
                    <Option value="687">New Caledonia (+687)</Option>
                    <Option value="64">New Zealand (+64)</Option>
                    <Option value="505">Nicaragua (+505)</Option>
                    <Option value="227">Niger (+227)</Option>
                    <Option value="234">Nigeria (+234)</Option>
                    <Option value="683">Niue (+683)</Option>
                    <Option value="672">Norfolk Islands (+672)</Option>
                    <Option value="670">Northern Marianas (+670)</Option>
                    <Option value="47">Norway (+47)</Option>
                    <Option value="968">Oman (+968)</Option>
                    <Option value="680">Palau (+680)</Option>
                    <Option value="507">Panama (+507)</Option>
                    <Option value="675">Papua New Guinea (+675)</Option>
                    <Option value="595">Paraguay (+595)</Option>
                    <Option value="51">Peru (+51)</Option>
                    <Option value="63">Philippines (+63)</Option>
                    <Option value="48">Poland (+48)</Option>
                    <Option value="351">Portugal (+351)</Option>
                    <Option value="1787">Puerto Rico (+1787)</Option>
                    <Option value="974">Qatar (+974)</Option>
                    <Option value="262">Reunion (+262)</Option>
                    <Option value="40">Romania (+40)</Option>
                    <Option value="7">Russia (+7)</Option>
                    <Option value="250">Rwanda (+250)</Option>
                    <Option value="378">San Marino (+378)</Option>
                    <Option value="239">Sao Tome &amp; Principe (+239)</Option>
                    <Option value="966">Saudi Arabia (+966)</Option>
                    <Option value="221">Senegal (+221)</Option>
                    <Option value="381">Serbia (+381)</Option>
                    <Option value="248">Seychelles (+248)</Option>
                    <Option value="232">Sierra Leone (+232)</Option>
                    <Option value="65">Singapore (+65)</Option>
                    <Option value="421">Slovak Republic (+421)</Option>
                    <Option value="386">Slovenia (+386)</Option>
                    <Option value="677">Solomon Islands (+677)</Option>
                    <Option value="252">Somalia (+252)</Option>
                    <Option value="27">South Africa (+27)</Option>
                    <Option value="34">Spain (+34)</Option>
                    <Option value="94">Sri Lanka (+94)</Option>
                    <Option value="290">St. Helena (+290)</Option>
                    <Option value="1869">St. Kitts (+1869)</Option>
                    <Option value="1758">St. Lucia (+1758)</Option>
                    <Option value="249">Sudan (+249)</Option>
                    <Option value="597">Suriname (+597)</Option>
                    <Option value="268">Swaziland (+268)</Option>
                    <Option value="46">Sweden (+46)</Option>
                    <Option value="41">Switzerland (+41)</Option>
                    <Option value="963">Syria (+963)</Option>
                    <Option value="886">Taiwan (+886)</Option>
                    <Option value="7">Tajikstan (+7)</Option>
                    <Option value="66">Thailand (+66)</Option>
                    <Option value="228">Togo (+228)</Option>
                    <Option value="676">Tonga (+676)</Option>
                    <Option value="1868">Trinidad &amp; Tobago (+1868)</Option>
                    <Option value="216">Tunisia (+216)</Option>
                    <Option value="90">Turkey (+90)</Option>
                    <Option value="7">Turkmenistan (+7)</Option>
                    <Option value="993">Turkmenistan (+993)</Option>
                    <Option value="1649">Turks &amp; Caicos Islands (+1649)</Option>
                    <Option value="688">Tuvalu (+688)</Option>
                    <Option value="256">Uganda (+256)</Option>
                    <Option value="44">UK (+44)</Option>
                    <Option value="380">Ukraine (+380)</Option>
                    <Option value="971">United Arab Emirates (+971)</Option>
                    <Option value="598">Uruguay (+598)</Option>
                    <Option value="1">USA (+1)</Option>
                    <Option value="7">Uzbekistan (+7)</Option>
                    <Option value="678">Vanuatu (+678)</Option>
                    <Option value="379">Vatican City (+379)</Option>
                    <Option value="58">Venezuela (+58)</Option>
                    <Option value="84">Vietnam (+84)</Option>
                    <Option value="84">Virgin Islands - British (+1284)</Option>
                    <Option value="84">Virgin Islands - US (+1340)</Option>
                    <Option value="681">Wallis &amp; Futuna (+681)</Option>
                    <Option value="969">Yemen (North)(+969)</Option>
                    <Option value="967">Yemen (South)(+967)</Option>
                    <Option value="260">Zambia (+260)</Option>
                    <Option value="263">Zimbabwe (+263)</Option>
                  </Select>
                </div>

                <div style={{ marginTop: 10 }}>
                  <Input size='large' onChange={(e) => this.getUserPhoneNumber(e)} />
                </div>

                <div style={{ marginTop: 10 }}>
                  <Button style={{ backgroundColor: '#2F4F4F', border: '1px solid #2F4F4F', color: '#FFFFFF' }} size='large' loading={this.state.validPhoneNumber} onClick={(e) => this.otpRequestToServer(e, 1)}><Text strong>&#x2003;Request OTP</Text></Button>
                </div>
              </Card>
            </center>
          </Content>

          <Content style={{ marginTop: '0px 0px 0px 0px', padding: '0px 0px 0px 0px', width: '100%', display: this.state.otpReceivedDiv }}>
            <center>
              <Card style={{ padding: '0px 0px 0px 0px', padding: '0px 0px 0px 0px', width: 200 }}>
                <div>
                  <Input size='large' placeholder="Enter OTP" onChange={(e) => this.getUserPhoneOTP(e)} />
                </div>
                <div style={{ marginTop: 10 }}>
                  <Button loading={this.state.verifyUserOtp} style={{ backgroundColor: '#2F4F4F', border: '1px solid #2F4F4F', color: '#FFFFFF' }} size='large' onClick={(e) => this.verifyWithOTP(e, 1)}><Text style={{ color: '#FFFFFF' }} strong>&#x2003;Verify OTP</Text></Button>
                </div>
              </Card>
            </center>
          </Content>

          <Content style={{ display: this.state.captchaDiv, marginTop: '0px 0px 0px 0px', padding: '0px 0px 0px 0px', width: '100%' }}>
            <center>
              <div id="recaptcha-container"></div>
            </center>
          </Content>

          <Content style={{ display: this.state.logoutDiv }}>
            <Card>
              <Title level={5}>Greetings from Cartwinks. Our sincere thanks for being our valuable customer!</Title>
            </Card>
          </Content>

          <Content style={{ marginTop: 50, paddingBotton: 20 }}>
            <center>
              <p><a href="https://signupforservices.com/whatsapp/optin/?bId=0573f70e-fb9e-45f4-9f51-a64dcaee7407&bName=CartWinks&s=URL&lang=en_US"><img width={60} src={window.location.origin + '/whatsapp-icon.png'} alt="WhatsApp" /></a></p>
              <p>Please click the WhatsApp icon and<br/>accept us to send delivery status.</p>
              <p>We will not send anything other <br/>than your delivery related messages.</p>
            </center>
          </Content>

          <Tabs defaultActiveKey="2" onChange={(e) => this.tabsClicked(e)} style={{marginTop: 50, marginLeft: 10, marginRight: 10, display:((this.state.loginDiv=="block") ? 'none' : 'block') }}>

            <TabPane tab="Address" key="user-address">
              <Content style={{ backgroundColor: "#FFFFFF" }}>
                {Object.values(this.state.userAddressesById).map((address, i) => (
                  <div key={"address-" + i}>
                      <div style={{marginLeft:0, marginTop:10}}>{address.name}, {address.address_1}, {address.address_2}, <br />{address.city}, {address.pincode}, <br />{address.state}</div>
                      <div style={{marginLeft:0, marginTop:10, marginBottom:10}}>
                        <Space size={10}><Button size='small' onClick={(e) => this.editUserAddressById(address.id)}><i className="fas fa-edit"></i>&#160;Edit</Button>
                        <Button danger size='small' onClick={(e) => this.deleteUserAddressById(address.id)}><i className="far fa-trash-alt"></i>&#160;Remove</Button>
                        </Space>
                      </div>
                    <hr />
                  </div>
                ))}
                <div>
                  <center>
                    <div><Button type="primary" size='large' onClick={(e) => this.editUserAddressById(1)}><i className="fas fa-plus"></i>&#x2003;New Address</Button></div>
                  </center>
                </div>
              </Content>
            </TabPane>

            <TabPane tab="Vouchers" key="vouchers">
              <Content style={{ backgroundColor: "#FFFFFF" }}>
                <p>Sorry. You have no vouchers now.</p>
              </Content>
            </TabPane>

          </Tabs>


          <Content style={{ display: this.state.editUserAddressDiv, marginLeft: 10, marginRight: 10 }}>
            <Card>
              <Form {...layout} name="address_form" onSubmit={this.handleClick} ref={this.addressFormRef}>
                <Form.Item name="name" label="Name" rules={[{ required: true }]}>
                  <Input size='large' onChange={(e) => this.userAddressInput(e, 'name')} />
                </Form.Item>
                <Form.Item name="address_1" label="Address 1" rules={[{ required: true }]}>
                  <Input size='large' onChange={(e) => this.userAddressInput(e, 'address_1')} />
                </Form.Item>
                <Form.Item name="address_2" label="Address 2">
                  <Input size='large' onChange={(e) => this.userAddressInput(e, 'address_2')} />
                </Form.Item>
                <Form.Item name="city" label="City" rules={[{ required: true }]}>
                  <Input size='large' onChange={(e) => this.userAddressInput(e, 'city')} />
                </Form.Item>
                <Form.Item name="pincode" label="Pincode" rules={[{ required: true }]}>
                  <Input size='large' onChange={(e) => this.userAddressInput(e, 'pincode')} />
                </Form.Item>
                <Form.Item name="state" label="State" rules={[{ required: true }]}>
                  <Input size='large' onChange={(e) => this.userAddressInput(e, 'state')} />
                </Form.Item>
                <Form.Item name="country" label="Country" rules={[{ required: true }]}>
                  <Input size='large' onChange={(e) => this.userAddressInput(e, 'country')} />
                </Form.Item>
                <Form.Item name="email" label="Email">
                  <Input size='large' onChange={(e) => this.userAddressInput(e, 'email')} />
                </Form.Item>
                <Form.Item {...tailLayout}>
                  {this.state.savedMessage ? (
                    <Button style={{ backgroundColor: '#2F4F4F', border: '1px solid #2F4F4F', color: '#FFFFFF' }} size='large' type="dashed"><Text style={{ color: '#FFFFFF' }} strong><i className="far fa-check-circle"></i>&#x2003;Saved&#x2003;</Text></Button>
                  ) : (
                    <Button style={{ backgroundColor: '#2F4F4F', border: '1px solid #2F4F4F', color: '#FFFFFF' }} size='large' type="primary" onClick={(e) => this.saveUserDetails(e, 1)}><Text style={{ color: '#FFFFFF' }} strong><i className="fas fa-save"></i>&#x2003;Save&#x2003;</Text></Button>
                  )
                  }
                </Form.Item>
              </Form>
            </Card>
          </Content>

          <Content style={{ display: this.state.logoutDiv, marginTop: 30, paddingBottom:20 }}>
            <center>
              <Button style={{ backgroundColor: '#2F4F4F', border: '1px solid #2F4F4F' }} onClick={(e) => this.signOutTheUser(e)} ><Text style={{ color: '#FFFFFF' }} strong><i className="fas fa-power-off"></i>&#x2003;Logout</Text></Button>
            </center>
          </Content>

          <Modal title="Login is required" visible={this.state.modalAskSignin} onOk={this.handleOk} onCancel={this.handleCancel}>
            <p>Please login using the OTP before adding your address.</p>
          </Modal>

          <CartwinksFooter/>
        </Layout>
      </div>
    )
  }
}
export default PhoneAuth
