import React from "react";
import logo from "./logo.jpeg";
import firebaseApp from "./Firebase";
import SideMenu from "./SideMenu";
import { Divider, Card, Table, Typography, InputNumber, Input, Layout, Menu, Drawer, Button, Radio, Space } from "antd";
import { MessageOutlined } from "@ant-design/icons";
import * as queryString from 'query-string';
import Helper from "./Helper"
import { withRouter } from "react-router-dom";
import CartwinksFooter from "./CartwinksFooter"

const { SubMenu } = Menu;
const { Title, Text } = Typography;
const { Header, Footer, Sider, Content } = Layout;
const { Meta } = Card;
let cartByProductId = {};

class OrderedCart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      "userSelection": {},
      cartById: {},
      productById: {},
      visible: false,
      placement: "left",
      collapsed: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }




  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };



  toggleCollapsed = () => {
    this.setState({
      //collapsed: !this.state.collapsed,
      visible: true,
    });
  };


  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  onChange = (e) => {
    this.setState({
      placement: e.target.value,
    });
  };

  discussProductItem = (e, product) =>
  {
    console.log(product);
    window.location.href = "https://wa.me";
  }

  
  displayUserCartItems = (queryPromiseD) => {
    const productById = {};
    queryPromiseD.then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        const eachProduct = doc.data();
        const eachCartProduct = cartByProductId[doc.id];
        eachProduct["id"] = doc.id;
        const productCartMerged = Object.assign({}, eachProduct, eachCartProduct);
        productById[doc.id] = productCartMerged;
      });
      console.log("displayUserCartItems", productById);
      this.setState({ "productById": productById });
    });
  }


  findUserCartDetails = (queryPromiseA) => {
    const productIds = [];
    queryPromiseA.then((querySnapshot) => {
    querySnapshot.forEach((doc) => {
      const { product_id } = doc.data();
      console.log(doc.data());
      productIds.push(product_id);
      cartByProductId[product_id] = doc.data();
    });
    
    console.log("productIds", productIds);
    if(productIds.length == 0) return; 
    const userId = localStorage.getItem("currentUserId");

    var ddd=0;
    Helper.sliceBySize(productIds,10).forEach((eachSet) =>{
      if(ddd==1){return;}
      var queryPromise = firebaseApp.findProductsByIdIn(eachSet);
      this.displayUserCartItems(queryPromise);
      ddd=1;
    });

    });
  };

  componentDidMount()
  {
    Helper.openLoginPage();
    const urlRoute = Helper.getUrlRoute(window.location.pathname);
    console.log("url route");
    console.log(urlRoute);
    const userId = localStorage.getItem("currentUserId");
    var queryPromiseCart = firebaseApp.findUserCartDetailsByOrderId(userId, urlRoute["order"]);
    this.findUserCartDetails(queryPromiseCart);
  }

  openStoresPage = () => {
    console.log("showing stores page");
    const { history } = this.props;
    if(history) history.push("/stores")
  }

  historyBack = () =>
  {
    const { history } = this.props;
    if(history) history.push("/orders");
  }

  render() {
    return (
      <div>
        <Layout style={{ backgroundColor: "#FFFFFF", minHeight: "100vh" }}>
            <Header style={{position: 'fixed', zIndex: 1, width: '100%', backgroundColor:"#003333", paddingLeft: 0 }}>
              <SideMenu/>
            </Header>

            <Divider  className="divider">Products in order</Divider>
            <Content style={{ backgroundColor: "#FFFFFF" }}>
            { Object.values(this.state.productById).map((product, i) => (
              <Card hoverable style={{ marginTop:0 }} key={"product_"+i}>
                <div><Title level={5}>{product.product_name}</Title></div>
                <div><Text>Price:{product.price}, Unit: {product.unit}, Ordered Unit: {product.order_unit}, Total:&#160;<span style={window.priceCss}>₹{product.total}</span> <Button type="link" onClick={(e) => this.discussProductItem(e, product)} icon={<i className="fa fa-comments"/>} size={5} /></Text></div>
            </Card>
            ))}
            </Content>

            <Content style={{ backgroundColor: "#FFFFFF" }}>
              <center><Button type="primary" onClick={(e) => this.historyBack()} size={5}>Back</Button></center>
            </Content>
            <CartwinksFooter/>
        </Layout>
      </div>
    );
  }

  handleChange(e) {
    this.setState({ text: e.target.value });
  }

  handleSubmit(e) {
    e.preventDefault();
    if (this.state.text.length === 0) {
      return;
    }
    const newItem = {
      text: this.state.text,
      id: Date.now(),
    };
    this.setState((state) => ({
      items: state.items.concat(newItem),
      text: "",
    }));
  }
}

export default withRouter(OrderedCart);