import React from "react";
import { List, Card, Table, Typography, InputNumber, Input, Layout, Menu, Drawer, Button, Radio, Space } from "antd";
import { withRouter } from "react-router-dom";

const { SubMenu } = Menu;
const { Title, Text } = Typography;
const { Header, Footer, Sider, Content } = Layout;
const { Meta } = Card;


class SideMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userSelection: {},
      products: [],
      productsById: {},
      visible: false,
      placement: "left",
      collapsed: false,
    }
  }



  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  openCartPage = () => {
    const { history } = this.props;
    if (history != undefined) history.push("/cart")
  }

  openOrderPage = () => {
    const { history } = this.props;
    if (history != undefined) history.push("/orders");
  }

  openMyOwnStoresPage = () => {
    const { history } = this.props;
    if (history != undefined) history.push("/myownstores");
  }

  openStoresPage = () => {
    console.log("showing stores page");
    const { history } = this.props;
    if (history) history.push("/stores")
  }

  openMyFavoriteStoresPage = () => {
    console.log("showing stores page");
    const { history } = this.props;
    if (history) history.push("/mystores")
  }

  openFavoriteProducts = () => {
    console.log("showing stores page");
    const { history } = this.props;
    if (history) history.push("/user/favorite/products")
  }

  openHomePage = () => {
    console.log("showing home page");
    const { history } = this.props;
    if (history) history.push("/")
  }

  openPhoneAuth = () => {
    const { history } = this.props;
    if (history) history.push("/login");
  }

  toggleCollapsed = () => {
    this.setState({
      //collapsed: !this.state.collapsed,
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  onChange = (e) => {
    this.setState({
      placement: e.target.value,
    });
  };

  componentDidMount() {

  }



  render() {

    return (
      <div>
        <Space>
          <Button size={25} onClick={this.toggleCollapsed} style={{ marginLeft: 10 }}> {this.state.collapsed ? <i className="fas fa-bars"></i> : <i className="fas fa-bars"></i>} </Button>
          <Drawer
            title={<span onClick={(e) => this.openHomePage()}><strong><i className="fa fa-home" /> Home </strong></span>}
            placement={this.state.placement}
            closable={false}
            onClose={this.onClose}
            visible={this.state.visible}
            key={this.state.placement}
            zindex={1}
            style={{
              marginLeft: 0,
              marginRight: 0,
              paddingLeft: 0,
              paddingRight: 0,
            }}
          >
            <Menu
              zindex={1000}
              //defaultSelectedKeys={["1"]}
              //defaultOpenKeys={["sub1"]}
              style={{ marginLeft: 0, marginRight: 0, paddingLeft: 0, paddingRight: 0 }}>
              <Menu.Item key="1" icon={<i className="fas fa-store"></i>} onClick={this.openStoresPage}>
                All Stores
              </Menu.Item>
              <Menu.Item key="3" icon={<i className="fas fa-shopping-cart"></i>} onClick={this.openCartPage}>
                Go Cart
              </Menu.Item>
              <Menu.Item key="4" icon={<i className="fas fa-shopping-bag"></i>} onClick={this.openOrderPage}>
                Placed Orders
              </Menu.Item>
              <Menu.Item key="MyOwnShops" icon={<i className="far fa-building"></i>} onClick={this.openMyOwnStoresPage}>
                Own Stores
              </Menu.Item>
              <Menu.Item key="phoneAuthMenu" icon={<i className="fas fa-user-circle"></i>} onClick={this.openPhoneAuth}>
                Profile
              </Menu.Item>
              <SubMenu key="Favorites" icon={<i className="far fa-heart"></i>} title="Favorites">
                <Menu.Item key="favorites:stores" icon={<i className="fas fa-store"></i>} onClick={this.openMyFavoriteStoresPage}>Stores</Menu.Item>
                <Menu.Item key="favorites:products" icon={<i className="fas fa-shopping-bag"></i>} onClick={this.openFavoriteProducts}>Products</Menu.Item>
              </SubMenu>
              <SubMenu key="SubMenu" icon={<i className="fas fa-list"></i>} title="Categories">
                <Menu.ItemGroup title="Groceries">
                  <Menu.Item key="groceries:daily-needs" onClick={(e) => window.location.href="/" }>Daily Needs</Menu.Item>
                </Menu.ItemGroup>
              </SubMenu>
            </Menu>
          </Drawer>
          <div className="appHeader">cartwinks</div>
        </Space>
      </div>
    );
  }
}
export default withRouter(SideMenu);
