import React from "react";
import firebaseApp from "./Firebase";
import SideMenu from "./SideMenu";
import { Button, Divider, Card, Typography, Layout, Space } from "antd";
import Helper from "./Helper";
import CartwinksFooter from "./CartwinksFooter"

const { Title, Text } = Typography;
const { Header, Content } = Layout;
let userId = null;

const deliveryStatus = { "0":"Door Delivery", "1":"I will pickup from the store" }
const orderStatus = { "0":"Order Placed", "1":"Packaging", "2":"Transit", "10":"Delivered" }
const paymentStatus = { "0":"UNPAID", "1":"PAID", "3":"FREE" }

class UserOrders extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      "userSelection": {},
      "userOrderById": {},
      visible: false,
      placement: "left",
      collapsed: false,
    }
  }


  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };


  toggleCollapsed = () => {
    this.setState({ visible: true });
  };


  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  onChange = (e) => {
    this.setState({
      placement: e.target.value,
    });
  };



  fetchUserOrders = (queryPromise) => {
    const userOrderById = {};
    queryPromise.then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
          userOrderById[doc.id] = doc.data();
          userOrderById[doc.id]["id"] = doc.id;
      });
      this.setState({ "userOrderById": userOrderById });
    });
  }



  componentDidMount() {
    Helper.openLoginPage();
    userId = localStorage.getItem("currentUserId");
    //firebase.firestore().collection("user_orders").where("user_id","==",userId).orderBy("created_at","desc").limit(100).onSnapshot(this.fetchUserOrders);
    var queryPromise= firebaseApp.findUserOrderByUserId(userId);
    this.fetchUserOrders(queryPromise);
  }

  discussCartItem = (e, cartItem) =>
  {
    window.location.href = "https://wa.me"
  }



  showProcessedCart = (e, orderId) => {
    const { history } = this.props;
    if(history) history.push("/order/"+orderId);
  }



  render() {
    return (
      <div>
        <Layout style={{ backgroundColor: "#FFFFFF", minHeight: "100vh" }}>
            <Header style={{position: 'fixed', zIndex: 1, width: '100%', backgroundColor:"#003333", paddingLeft: 0 }}>
                <SideMenu/>
            </Header>

            <Content style={{ backgroundColor: "#FFFFFF" }}>
            <Divider  className="divider"><i className="far fa-list-alt"></i> Order Details</Divider>
            { Object.values(this.state.userOrderById).map((order, i) => (
              <Card hoverable style={{ marginTop:0 }} key={"order_" + i}>
                <Title level={5}>Total Items: <span style={window.priceCss}>{order.items}</span>, Total Value: <span style={window.priceCss}>₹{order.total}</span> <Space size={15}/> </Title>
                <p>
                  <Text type="secondary">Delivery Mode: <span style={{fontWeight:"bold"}}>{deliveryStatus[order.delivery_type_id]}</span></Text> <br/>
                  <Text type="secondary">Delivery Status: <span style={{fontWeight:"bold"}}>{orderStatus[order.status]}</span></Text><br/>
                  <Text type="secondary">Date: {new Date(order.created_at.toDate()).toUTCString()}</Text><br/>
                  <Text type="secondary">Show Details: <Typography.Link onClick={(e) => this.showProcessedCart(e, order.id)}><span style={{fontWeight:"bold"}}><i className="fas fa-luggage-cart"></i> ORDER DETAILS</span></Typography.Link><Typography.Link style={{marginLeft:"10px"}} type="link" onClick={(e) => this.discussCartItem(e, order)} size={3}><i className="fa fa-comments"></i></Typography.Link></Text><br/>
                  <Text type="secondary">Payment Status: <span style={{fontWeight:"bold"}}>{paymentStatus[order["payment_status"]]}</span> {(order["payment_status"]==0) && <Button value="small" type="link"><a href={order["cartwinks_payment_link"]}><span style={{fontWeight:"bold"}}><i class="far fa-credit-card"></i> CLICK TO PAY</span></a></Button>}</Text>
                </p>
            </Card>
            ))}
            </Content>
            <CartwinksFooter/>
          </Layout>
      </div>
    );
  }
}

export default UserOrders;