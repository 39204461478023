import React from "react";
import logo from "./logo.jpeg";
import firebaseApp from "./Firebase";
import { Image, Skeleton, Divider, Tabs, Row, Col, Card, Table, Typography, InputNumber, Input, Layout, Menu, Drawer, Button, Radio, Space } from "antd";
import SideMenu from "./SideMenu";
import * as queryString from 'query-string';
import Helper from "./Helper"
import { FacebookShareButton, TelegramShareButton, TwitterShareButton, WhatsappShareButton} from "react-share";
import CartwinksFooter from "./CartwinksFooter"

const { TabPane } = Tabs;
const { Title, Text } = Typography;
const { Header, Footer, Sider, Content } = Layout;
let userId = null;
let storeId = null;
let currentProjectId = null;

class StoreProducts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bannerLoading: true,
      productsLoading: true,
      userSelection: {},
      productsById: {},
      visible: false,
      placement: "left",
      collapsed: false,
      storeDetails: { "bookmark": 0 },
      cartTotal: 0,
      categoriesById: {}
    }
  }

  unitChanged = (value, productId) => {
    const totalUnits = Helper.getInteger(value);
    const productsById = this.state.productsById;
    var selectedProduct = productsById[productId];
    selectedProduct["order_unit"] = totalUnits;
    this.reworkCartDetails(selectedProduct);
  }


  openCartPage = () => {
    window.location.href = "cart";
  }



  removeFromCart = (e, productId) => {
    let productsById = this.state.productsById;
    let selectedProduct = productsById[productId];
    firebaseApp.deleteUserCartById(selectedProduct["cart_id"]);
    //firebase.firestore().collection("user_carts").doc(selectedProduct["cart_id"]).delete();
    selectedProduct = Helper.resetCartItem(selectedProduct);
    delete selectedProduct["cart_id"];
    productsById[productId] = selectedProduct;
    this.setState({ "productsById": productsById, "cartTotal": Helper.getCartTotal(productsById) });
  }



  addToCart = (e, productId) => {
    var selectedProduct = this.state.productsById[productId];
    selectedProduct["user_id"] = userId;
    selectedProduct["product_id"] = productId;
    selectedProduct["order_unit"] = Helper.getInteger(selectedProduct["order_unit"]);
    selectedProduct["order_unit"] = selectedProduct["order_unit"] + 1;
    this.reworkCartDetails(selectedProduct);
  }

  reduceToCart = (e, productId) => {
    var selectedProduct = this.state.productsById[productId];
    selectedProduct["user_id"] = userId
    selectedProduct["product_id"] = productId;
    selectedProduct["order_unit"] = Helper.getInteger(selectedProduct["order_unit"]);
    selectedProduct["order_unit"] = (selectedProduct["order_unit"] - 1);
    this.reworkCartDetails(selectedProduct);
  }


  reworkCartDetails = (selectedProduct) => {
    let cartId = userId + "_" + new Date().getTime();
    const productId = selectedProduct["product_id"];
    var queryPromise = firebaseApp.findUserCartItemByProductIdAndOrderId(userId, productId, 0);
    queryPromise.then((querySnapshot) => {
      if (querySnapshot.size == 0) {
        console.log("not exits", userId, productId, 0);
        selectedProduct["cart_id"] = cartId;
        this.updateCartDetails(selectedProduct);
      }
      querySnapshot.forEach((doc) => {
        console.log("The Product is already in cart ", doc.data());
        selectedProduct["cart_id"] = doc.id;
        this.updateCartDetails(selectedProduct);
      });
    }).catch((error) => {
      console.log("Error getting documents: ", error);
      selectedProduct["cart_id"] = cartId;
      this.updateCartDetails(selectedProduct);
    });
  }

  updateCartDetails = (selectedProduct) => {
    selectedProduct["total"] = (selectedProduct["order_unit"] * selectedProduct["price"]);
    selectedProduct["order_id"] = 0;
    selectedProduct["created_at"] = firebaseApp.findServerTime();

    let productForCart = { "total": selectedProduct["total"], "order_id": 0, "created_at": firebaseApp.findServerTime() };
    productForCart["user_id"] = userId;
    productForCart["order_unit"] = selectedProduct["order_unit"];
    productForCart["product_id"] = selectedProduct["product_id"];
    productForCart["product_name"] = selectedProduct["product_name"];
    firebaseApp.saveUserCarts(selectedProduct["cart_id"], productForCart);

    const productsById = this.state.productsById;
    productsById[selectedProduct.id] = selectedProduct;
    this.setState({ "productsById": productsById, "cartTotal": Helper.getCartTotal(productsById) });
  }

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  onChange = (e) => {
    this.setState({
      placement: e.target.value,
    });
  };



  fetchStoreProducts = (queryPromise) => {
    const productsById = {};
    queryPromise.then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        let productData = doc.data();
        productData["id"] = doc.id;
        productData = Helper.resetCartItem(productData);
        productData["price"] = Helper.getInteger(productData["price"]);
        if (productData["image"] != undefined) {
          if (productData["image"].startsWith("public/")) {
            productData["image"] = `https://storage.googleapis.com/${currentProjectId}-open-assets/${productData["image"]}`;
          }
          else {
            productData["image"] = `https://storage.googleapis.com/${currentProjectId}-open-assets/${productData["business_id"]}/${productData["image"]}`;
          }
        }
        productsById[doc.id] = productData;
      });
      this.setState({ "productsById": productsById, "productsLoading": false });
    });
  };

  addStoreBanner = (docPromise) => {
    docPromise.then((doc) => {
      console.log(doc.data());
      if (!doc.exists) { return; }
      let storeDetails = {};
      storeDetails = doc.data();
      if (storeDetails == undefined) {
        //todo no store display
        return;
      }
      console.log("addStoreBanner function");
      storeDetails["id"] = doc.id;
      storeDetails["bookmark"] = 0;
      this.setState({ "storeDetails": storeDetails, "bannerLoading": false });

      var queryPromise = firebaseApp.findProductsByStoreId(storeId);
      this.fetchStoreProducts(queryPromise);

      const categoryQuerySnapshot = firebaseApp.findStoreCategoryByStoreId(storeId);
      this.findStoreCategories(categoryQuerySnapshot);

    });
  };

  openStoresPage = () => {
    console.log("showing stores page");
    const { history } = this.props;
    if (history) history.push("/stores")
  }

  findStoreCategories = (querySnapshot) => {
    querySnapshot.then((doc) => {
      if (!doc.exists) { return; }
      const docData = doc.data();
      if (docData != null) this.setState({ "categoriesById": docData });
    });
  }

  tabsClicked = (categoryId) => {
    if ((categoryId == null) || (categoryId.length == 0)) return;
    const querySnapshot = firebaseApp.findProductsByStoreIdAndCategoryId(storeId, categoryId, window.productLength);
    this.fetchStoreProducts(querySnapshot);
  }

  componentDidMount() {
    currentProjectId = firebaseApp.findProjectId();
    Helper.openLoginPage();
    const urlRoute = Helper.getUrlRoute(window.location.pathname);
    console.log(urlRoute);
    userId = localStorage.getItem("currentUserId");
    console.log(userId);
    storeId = urlRoute["stores"];
    console.log("Current store id", urlRoute["stores"]);
    var queryPromise = firebaseApp.findStoreById(urlRoute["stores"]);
    this.addStoreBanner(queryPromise);
  }



  onStoreBookmarkClicked = (e, storeId) => {
    const docId = userId + "_" + storeId;
    const storeDetails = this.state.storeDetails;
    storeDetails["bookmark"] = 1;
    firebaseApp.saveUserStores(docId, { "user_id": userId, "store_id": storeId, "business_id": storeDetails["business_id"], "created_at": firebaseApp.findServerTime() });
    this.setState({ "storeDetails": storeDetails });
  }

  
  onProductBookmarkClicked = (e, productId) => {
    firebaseApp.bookmarkProductByUser(productId, { "user_id": userId, "created_at": firebaseApp.findServerTime() });
  }

  onStoreUnBookmarkClicked = (e, storeId) => {
    const docId = userId + "_" + storeId;
    //firebase.firestore().collection("user_stores").doc(docId).delete();
    firebaseApp.deleteUserStoreById(docId);
    const storeDetails = this.state.storeDetails;
    storeDetails["bookmark"] = 0;
    this.setState({ "storeDetails": storeDetails });
  };


  render() {

    return (
      <div>
        <Layout>
          <Header style={{position: 'fixed', zIndex: 1, width: '100%', backgroundColor:"#003333", paddingLeft: 0 }}>
            <SideMenu />
          </Header>

          <Skeleton loading={this.state.bannerLoading} active avatar>
            <Content style={{ backgroundColor: "#FFFFFF" }}>
              <Card hoverable style={{ marginTop: 50 }} id="storebanner" cover={<img alt="example" src={this.state.storeDetails["banner_image"]} />} >

                <center>
                  <Title className="storeName" level={3}>{this.state.storeDetails.store_name}</Title>
                  <Title level={5}>{this.state.storeDetails.address}, {this.state.storeDetails.city}. PH:&#160;<a href={'tel://' + this.state.storeDetails.phone}>{this.state.storeDetails.phone}</a></Title>
                </center>

                <div>
                  <center>
                    {(this.state.storeDetails.bookmark == 0) ?
                      <Space>
                        <Button type="primary" onClick={(e) => this.onStoreBookmarkClicked(e, this.state.storeDetails.id)} icon={<i className="fa fa-bookmark" />}></Button>
                        <Button type="primary" onClick={(e) => this.openStoresPage()} icon={<i className="fa fa-arrow-circle-left"></i>}></Button>
                        <div type="primary" style={{padding:"5px 10px 5px 10px", borderRadius:"3px", backgroundColor:"#5898fc"}}><WhatsappShareButton url={window.location}><i className="fab fa-whatsapp"></i></WhatsappShareButton></div>
                        <div type="primary" style={{padding:"5px 10px 5px 10px", borderRadius:"3px", backgroundColor:"#5898fc"}}><FacebookShareButton url={window.location}><i className="fab fa-facebook-square"></i></FacebookShareButton></div>
                      </Space>
                      :
                      <Space>
                        <Button type="primary" style={{ backgroundColor: '#008000', borderColor: '#008000' }} onClick={(e) => this.onStoreUnBookmarkClicked(e, this.state.storeDetails.id)} icon={<i className="fa fa-trash-alt"></i>}></Button>
                        <Button type="primary" onClick={(e) => this.openStoresPage()} icon={<i className="fa fa-arrow-circle-left"></i>}></Button>
                        <div type="primary" style={{padding:"5px 10px 5px 10px", borderRadius:"3px", backgroundColor:"#5898fc"}}><WhatsappShareButton url={window.location}><i className="fab fa-whatsapp"></i></WhatsappShareButton></div>
                        <div type="primary" style={{padding:"5px 10px 5px 10px", borderRadius:"3px", backgroundColor:"#5898fc"}}><FacebookShareButton url={window.location}><i className="fab fa-facebook-square"></i></FacebookShareButton></div>
                      </Space>
                    }
                  </center>
                </div>
              </Card>
            </Content>
          </Skeleton>

          <Skeleton loading={this.state.productsLoading} active>

            <Tabs onChange={(e) => this.tabsClicked(e)} style={{ height: 48, marginLeft: 10, paddingBottom: 0, marginRight: 10 }}>
              {Object.values(this.state.categoriesById).map((category, i) => (
                <TabPane tab={category.name} key={category.id} />
              ))}
            </Tabs>

            <Content style={{ backgroundColor: "#FFFFFF" }}>
              {Object.values(this.state.productsById).map((product, i) => (
                <Card hoverable style={{ marginTop: 0 }} key={"product_" + i}>
                  {(product["image"] == undefined) ? (
                    <div key={"without-image-"+product.id}>
                      <Title style={{color:"#663300"}} level={5}>{product.product_name}</Title>
                      {
                        (product["order_unit"] > 0) ? (
                          <div key={"with-quantity-"+product.id}>
                            <div><p><Text type="default">Price: {product.price}, Unit: {product.unit}, Qty:&#160;{product["order_unit"]}, Amt:&#160;<span style={window.priceCss}>₹{product["total"]}</span></Text></p></div>
                            <div><Space size={10}>
                              <InputNumber type="number" min={0} max={100} style={{ width: 50 }} defaultValue={product["order_unit"]} value={product["order_unit"]} onChange={(e) => this.unitChanged(e, product.id)} />
                              <Button style={{backgroundColor:"#ff9900"}} onClick={(e) => this.addToCart(e, product.id)} icon={<i className="fas fa-plus"></i>} size={5} ></Button>
                              <Button onClick={(e) => this.reduceToCart(e, product.id)} icon={<i className="fas fa-minus"></i>} size={5} ></Button>
                              <Button style={{ color: '#FF4500', borderColor: '#FF4500' }} onClick={(e) => this.removeFromCart(e, product.id)} icon={<i className="fa fa-trash-alt"></i>} size={5}></Button>
                              <Button type="link" onClick={(e) => this.openCartPage()} size={5}>Cart</Button>
                            </Space>
                            </div>
                          </div>
                        ) : (
                          <div key={"without-quantity-"+product.id}>
                            <div><p><Text type="default">Price: {product.price}, Unit: {product.unit}</Text></p></div>
                            <div>
                              <Space size={10}>
                                <Button style={{backgroundColor:"#ff9900"}} onClick={(e) => this.addToCart(e, product.id)} icon={<i className="fas fa-plus"></i>} size={5}>&#8194;Add to Cart</Button>
                                <Button onClick={(e) => this.onProductBookmarkClicked(e, product.id)} icon={<i className="fa fa-bookmark" />}></Button>
                              </Space>
                            </div>
                          </div>
                        )
                      }
                    </div>
                  ) : (
                    <div key={"with-image-"+product.id}>
                      <Space align="top" size={12}>
                        <div>
                          <div><Image width={100} src={product.image} /></div>
                          { (product["order_unit"] > 0) && (<div><Button style={{ marginLeft: 0, paddingLeft: 0 }} type="link" onClick={(e) => this.openCartPage()} size={5}>Cart</Button></div> )}
                        </div>
                        <div>
                          <Title style={{color:"#663300"}} level={5}>{product.product_name}</Title>
                          {
                            (product["order_unit"] > 0) ? (
                              <div key={"with-quantity-"+product.id}>
                                <div><p><Text type="default">Price: {product.price}, Unit: {product.unit}, Qty:&#160;{product["order_unit"]}, Amt:&#160;<span style={window.priceCss}>₹{product["total"]}</span></Text></p></div>
                                <div>
                                  <Space size={10}>
                                    <InputNumber type="number" min={0} max={100} style={{ width: 50 }} defaultValue={product["order_unit"]} value={product["order_unit"]} onChange={(e) => this.unitChanged(e, product.id)} />
                                    <Button style={{backgroundColor:"#ff9900"}} onClick={(e) => this.addToCart(e, product.id)} icon={<i className="fas fa-plus"></i>} size={5} ></Button>
                                    <Button onClick={(e) => this.reduceToCart(e, product.id)} icon={<i className="fas fa-minus"></i>} size={5} ></Button>
                                    <Button style={{ color: '#FF4500', borderColor: '#FF4500' }} onClick={(e) => this.removeFromCart(e, product.id)} icon={<i className="fa fa-trash-alt"></i>} size={5}></Button>
                                  </Space>
                                </div>
                              </div>
                            ) : (
                              <div key={"without-quantity-"+product.id}>
                                <div><Text type="default">Price: {product.price}, Unit: {product.unit}</Text></div>
                                <div style={{ marginTop: 6 }} >
                                  <Space size={10}>
                                    <Button style={{backgroundColor:"#ff9900"}} onClick={(e) => this.addToCart(e, product.id)} icon={<i className="fas fa-plus"></i>} size={5}>&#8194;Add to Cart</Button>
                                    <Button onClick={(e) => this.onProductBookmarkClicked(e, product.id)} icon={<i className="fa fa-bookmark" />}></Button>
                                  </Space>
                                  </div>
                              </div>
                            )
                          }
                        </div>
                      </Space>
                    </div>
                  )
                  }
                </Card>
              ))}


              <Card hoverable style={{ marginTop: 50 }} id="cost_details">
                <Divider className="divider">Cost Details</Divider>
                <div><Title level={5}>Total amount: <span style={window.totalCostCss}>₹{this.state.cartTotal}</span></Title></div>
                <div><Text type="secondary">Please note that we don't collect money. You can pay on delivery.</Text></div>
              </Card>

            </Content>
          </Skeleton>

          <CartwinksFooter />
        </Layout>
      </div>
    );
  }

}

export default StoreProducts;
