import React from "react";
import firebaseApp from "./Firebase";
import SideMenu from "./SideMenu";
import Helper from "./Helper";
import { Divider, Row, Col, Card, Typography, Layout, Menu, Button} from "antd";
import CartwinksFooter from "./CartwinksFooter"

const { SubMenu } = Menu;
const { Title, Text } = Typography;
const { Header, Footer, Sider, Content } = Layout;
const { Meta } = Card;
let userId = null;

class OwnStores extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      "userSelection": {},
      storesById: {},
      visible: false,
      placement: "left",
      collapsed: false,
      contactMessageDisplay: "block"
    }
  }




  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };


  toggleCollapsed = () => {
    this.setState({
      //collapsed: !this.state.collapsed,
      visible: true,
    });
  };



  onStoreShoppingClicked = (e, storeId) => {
    console.log(storeId);
    window.location.href = "stores/" + storeId;
  };
  
  onCheckMyStoreOrders = (e, storeId) => {
    console.log(storeId);
    //const storeObj = this.state.storesById[storeId];
    window.location.href = `myownstore/${storeId}/storeorders`;
  };
  

  onUserFavoriteStoreFetch = (queryPromise) => {
    console.log("onUserFavoriteStoreFetch", 11);
    const storesById = {};
    let contactMessageDisplay = "block";
    queryPromise.then((querySnapshot) => {
    querySnapshot.forEach((doc) => {
      console.log(doc.data());
      storesById[doc.id] = doc.data();
      storesById[doc.id]["id"] = doc.id;
      contactMessageDisplay = "none";
    });
    if(Object.keys(storesById).length==0) return;
    this.setState({ "storesById": storesById, "contactMessageDisplay": contactMessageDisplay });
    });
  }



  getMyOwnStores = (queryPromise) => {
    let storeIds = [];
    queryPromise.then((querySnapshot) => {
    querySnapshot.forEach((doc) => {
        const { store_id } = doc.data();
        console.log(store_id);
        if(storeIds.indexOf(store_id) != -1){ return; }
        storeIds.push(store_id);
    });
    console.log(storeIds);
    storeIds = storeIds.splice(0,10);
    if(storeIds.length==0) return;
    console.log(storeIds);
    var queryPromise= firebaseApp.findStoresByIdIn(storeIds);
    this.onUserFavoriteStoreFetch(queryPromise);
  });
  };



  componentDidMount() {
    Helper.openLoginPage();
    userId = localStorage.getItem("currentUserId");
    console.log(userId);
    var queryPromise= firebaseApp.findOwnStoresByUserId(userId);
    this.getMyOwnStores(queryPromise);
  }

  render() {

    return (
      <div>
        <Layout>
          <Layout>
            <Header style={{position: 'fixed', zIndex: 1, width: '100%', backgroundColor:"#003333", paddingLeft: 0 }}>
                <SideMenu/>
            </Header>

            <Content style={{ backgroundColor: "#FFFFFF" }}>
            { Object.values(this.state.storesById).map((store, i) => (
              <Card hoverable key={`store-${i}`} style={{ marginTop:50 }} cover={<img alt="example" src={store["banner_image"]} />} >
                <center><Title level={5}>Name: {store.store_name} ({store.store_id})</Title></center>
                <Row justify="space-between">
                    <Col xs={{ span: 5, offset: 1 }} lg={{ span: 6, offset: 2 }}>
                        <Button type="primary" onClick={(e) => this.onCheckMyStoreOrders(e, store.id)} icon={<i className="fas fa-shopping-cart"></i>}>&#x2003;Orders</Button>
                    </Col>
                    <Col xs={{ span: 11, offset: 1 }} lg={{ span: 6, offset: 2 }}>
                        <Button type="primary" onClick={(e) => this.onStoreShoppingClicked(e, store.id)} icon={<i className="fa fa-shopping-bag"/>}>&#x2003;Shoping</Button>
                    </Col>
                </Row>
            </Card>
            ))}
            </Content>
            
            <Content style={{ backgroundColor: "#FFFFFF" }} style={{display:this.state.contactMessageDisplay}}>
              <Divider  className="divider">How to bring my business online</Divider>
              <Card hoverable style={{ marginTop:50 }}>
                  <div><Title level={5}>We wish to see you here and all the very best.</Title></div>
                  <p><Text type="secondary">Dear Patron, It is our greatest pleasure to see you hear. Yes, we can assit you to bring your business online, with no cost.</Text></p>
                  <p><Text type="secondary">Please contact us at <a href="tel://+91 93424 50052">+91 93424 50052</a> or email us at <a href="mailto:priya@cartwinks.com">priya@cartwinks.com</a>. It may take maximum of 2 hours to bring your business online.</Text></p>
                  <p><Text type="secondary">It doesn't matter you may sell Rose or Rocket. Your business is important for you, we can assist you to get your business online.</Text></p>
                  <div><Title level={5}>Please note that this software and services are fully free. Our executives will not ask any money. Do not make any payment whatever mentioned.</Title></div>
              </Card>
            </Content>
            <CartwinksFooter/>
          </Layout>
        </Layout>
      </div>
    );
  }
  
}

export default OwnStores;