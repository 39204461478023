import React from "react";
import { Avatar, Image, Skeleton, Divider, Row, Col, Tabs, Card, Table, Typography, InputNumber, Input, Layout, Menu, Drawer, Button, Radio, Space } from "antd";


const { Footer } = Layout;

class CartwinksFooter extends React.Component {

  render() {
    return (
        <Footer className="footer-links" style={{backgroundColor:"#003333", color:"#ffffff"}}>
            <center>
              <a href="aboutus">About</a> | <a href="privacy">Privacy</a> | <a href="termsandcondition">Terms</a> | <a href="deliveryandshipment">Delivery</a> | <a href="refundandcancellation">Refund</a> | <a href="contactus">Contact</a> | <a target="_blank" href="https://sites.google.com/techiehug.com/cartwinks-blogs/home">Blogs</a>
            </center>
        </Footer>
    );
  }

}

export default CartwinksFooter;
