import React from "react";
import { getAuth, signInAnonymously, onAuthStateChanged } from "firebase/auth";
import firebaseApp from "./Firebase";

let currentTempUser = {};
let cartWinksApp = null;

//const firebaseAuth = firebase.getAuth();

class Helper extends React.Component {
    
    constructor(props) {
        cartWinksApp = this;
        super(props);
    }

    static openLoginPage = () =>
    {
        const firebaseAuth = getAuth();
        onAuthStateChanged(firebaseAuth, (authorizedUser) => {
            if (authorizedUser) {
                console.log(authorizedUser.uid);
                this.manageUserEntity(authorizedUser);
            }
            else
            { 
                signInAnonymously(firebaseAuth).then(function(anonymousUser) {
                    console.log(anonymousUser.user);
                    this.manageUserEntity(anonymousUser);
                })
                .catch((error) => {
                  const errorCode = error.code;
                  const errorMessage = error.message;
                  window.location.href = "/login";
                });
            }
        });
    }

    static manageUserEntity = (currentUser) =>
    {
        console.log("manageUserEntity");
        if(currentUser.isAnonymous==true)
        {
            localStorage.setItem("currentUserAnonymous", "true");
            localStorage.setItem("currentAnonymousUserId", currentUser.uid);
            localStorage.setItem("currentUserId", currentUser.uid);
        }
        else
        {
            console.log("assignAnonymousUserWithAuthUser");
            localStorage.setItem("currentUserAnonymous", "false");
            let currentAnonymousUserId = localStorage.getItem("currentAnonymousUserId");
            localStorage.setItem("currentUserId", currentUser.uid);
            if(currentAnonymousUserId!=null)
            {
                firebaseApp.assignAnonymousUserWithAuthUser(currentAnonymousUserId, currentUser.uid);
            }
            localStorage.removeItem("currentAnonymousUserId");
        }
    }


    static getCurrentUser = () =>
    {

    const firebaseAuth = getAuth();
    onAuthStateChanged(firebaseAuth, (authorizedUser) => {
            if (authorizedUser) {
                console.log("User auth state change");
                cartWinksApp.setState( {"uid": authorizedUser.id} );
                currentTempUser["uid"] = authorizedUser.uid;
                localStorage.setItem("currentUserAnonymous", authorizedUser.isAnonymous);
                localStorage.setItem("currentUserId", currentTempUser["uid"]);
            }
            else {
                console.log("Unauth auth state change");
                if((localStorage.getItem("currentUserId")==null) || (localStorage.getItem("currentUserId")==undefined))
                {
                    currentTempUser["uid"] = "cwu"+new Date().getTime();
                    localStorage.setItem("currentUserId", currentTempUser["uid"]);
                }
                else
                {
                    currentTempUser["uid"] = localStorage.getItem("currentUserId");
                }
            }
        });
        console.log(currentTempUser);
        return currentTempUser;
    }



    static getUrlRoute = (urlPath) =>
    {
        urlPath = urlPath.split("/");
        console.log(urlPath);
        var urlRoutes = {};
        for(var i=0; i<urlPath.length-1; i++)
        {
            urlRoutes[urlPath[i]] = urlPath[i + 1];
        }
        return urlRoutes;
    }



    static getCartTotal = (productsById) =>
    {
        let cartTotal = 0;
        for(var item in productsById)
        {
            cartTotal = cartTotal + productsById[item]["total"];
        }
        return cartTotal;
    }


    static sliceBySize = (inputArray, splitBy) =>
    {
        var resultArray = [];
        var newSlice = [];
        var beginAt = 0;
        var sliceAt = splitBy;

        do{
            newSlice = inputArray.slice(beginAt, sliceAt);
            resultArray.push(newSlice);
            beginAt = beginAt + splitBy;
            sliceAt = sliceAt + splitBy;
        }while(newSlice.length==splitBy);
        
        return resultArray;
    }



    static getCartTotalByStoreId = (productsById) =>
    {
        let storeByIds = {};
        for(var item in productsById)
        {
            const storeId = productsById[item]["store_id"];
            if(storeByIds[storeId]==undefined)
            {
                storeByIds[storeId] = {"total":0, "items":0};
            }
            storeByIds[storeId]["total"] += (productsById[item]["total"] * 1);
            storeByIds[storeId]["items"] += 1;
        }

        let storeByMetrics = [];
        for(var storeId in storeByIds)
        {
            storeByMetrics.push( {"store_id":storeId, "total":storeByIds[storeId]["total"], "items": storeByIds[storeId]["items"], "name":storeId} );
        }
        return storeByMetrics;
    }



    static getInteger = (givenInput) =>
    {
        if(givenInput > -1) return givenInput;
        return 0;
    }

    static resetCartItem = (givenProduct) =>
    {
        return Object.assign( givenProduct, { "total":0, "order_unit":0 } );
    }
}

export default Helper;
