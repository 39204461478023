import React from "react";
import logo from "./logo.jpeg";
import firebaseApp from "./Firebase";
import { Avatar, Image, Skeleton, Divider, Row, Col, Tabs, Card, Table, Typography, InputNumber, Input, Layout, Menu, Drawer, Button, Radio, Space } from "antd";
import SideMenu from "./SideMenu";
import Helper from "./Helper";
import CartwinksFooter from "./CartwinksFooter"

const { TabPane } = Tabs;
const { Title, Text } = Typography;
const { Header, Footer, Sider, Content } = Layout;
const { Meta } = Card;
let userId = null;
let currentProjectId = null;

class AllProducts extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      productsById: {},
      productsLoading: true,
      visible: false,
      collapsed: false,
      cartTotal: 0,
      productsReadyDiv: "none",
      categoriesById: {}
    }
  }

  fetchAllCategories = (querySnapshot) => {
    const categoriesById = {};
    querySnapshot.then((querySnapshot) => {
      querySnapshot.forEach(function (doc) {
        categoriesById[doc.id] = doc.data();
        categoriesById[doc.id]["id"] = doc.id;
      });
      this.setState({ "categoriesById": categoriesById });
    });
  }




  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  openCartPage = () => {
    window.location.href = "cart";
  }



  openOrderPage = () => {
    window.location.href = "order";
  }

  unitChanged = (value, productId) => {
    const totalUnits = Helper.getInteger(value);
    const productsById = this.state.productsById;
    var selectedProduct = productsById[productId];
    selectedProduct["order_unit"] = totalUnits;
    this.reworkCartDetails(selectedProduct);
  }


  onProductBookmarkClicked = (e, productId) => {
    let docId = userId + "-" + productId;
    firebaseApp.bookmarkProductByUser(docId, { "product_id": productId, "user_id": userId, "created_at": firebaseApp.findServerTime() });
  }

  addToCart = (e, productId) => {
    var selectedProduct = this.state.productsById[productId];
    selectedProduct["user_id"] = userId
    selectedProduct["product_id"] = productId;
    selectedProduct["product_name"] = selectedProduct["product_name"];
    selectedProduct["order_unit"] = Helper.getInteger(selectedProduct["order_unit"]);
    selectedProduct["order_unit"] = (selectedProduct["order_unit"] + 1);
    this.reworkCartDetails(selectedProduct);
  }

  reduceToCart = (e, productId) => {
    var selectedProduct = this.state.productsById[productId];
    selectedProduct["user_id"] = userId
    selectedProduct["product_id"] = productId;
    selectedProduct["order_unit"] = Helper.getInteger(selectedProduct["order_unit"]);
    selectedProduct["order_unit"] = (selectedProduct["order_unit"] - 1);
    this.reworkCartDetails(selectedProduct);
  }

  test = (dd) => {
    console.log(dd);
  }

  reworkCartDetails = (selectedProduct) => {
    let cartId = userId + "_" + new Date().getTime();
    const productId = selectedProduct["product_id"];
    var queryPromise = firebaseApp.findUserCartItemByProductIdAndOrderId(userId, productId, 0);
    queryPromise.then((querySnapshot) => {
      if (querySnapshot.size == 0) {
        console.log("not exits", userId, productId, 0);
        selectedProduct["cart_id"] = cartId;
        this.updateCartDetails(selectedProduct);
      }
      querySnapshot.forEach((doc) => {
        selectedProduct["cart_id"] = doc.id;
        this.updateCartDetails(selectedProduct);
      });
    });
  }



  updateCartDetails = (selectedProduct) => {
    selectedProduct["total"] = (selectedProduct["order_unit"] * selectedProduct["price"]);
    selectedProduct["order_id"] = 0;
    selectedProduct["created_at"] = firebaseApp.findServerTime();

    let productForCart = { "total": selectedProduct["total"], "order_id": 0, "created_at": firebaseApp.findServerTime() };
    productForCart["user_id"] = userId;
    productForCart["order_unit"] = selectedProduct["order_unit"];
    productForCart["product_id"] = selectedProduct["product_id"];
    productForCart["product_name"] = selectedProduct["product_name"];

    firebaseApp.saveUserCarts(selectedProduct["cart_id"], productForCart);

    const productsById = this.state.productsById;
    productsById[selectedProduct.id] = selectedProduct;
    this.setState({ "productsById": productsById, "cartTotal": Helper.getCartTotal(productsById) });
  }

  fetchAllProducts = (querySnapshot) => {
    const productsById = {};
    const businessProductId = {};
    querySnapshot.then((querySnapshot) => {  //Notice the arrow funtion which bind `this` automatically.
      querySnapshot.forEach(function (doc) {
        let productData = doc.data();
        productData["id"] = doc.id;
        if (businessProductId[productData["business_product_id"]] !== undefined) { return; }
        productData["price"] = Helper.getInteger(productData["price"]);
        if (productData["image"] != undefined) {
          if (productData["image"].startsWith("public/")) {
            productData["image"] = `https://storage.googleapis.com/${currentProjectId}-open-assets/${productData["image"]}`;
          }
          else {
            productData["image"] = `https://storage.googleapis.com/${currentProjectId}-open-assets/${productData["business_id"]}/${productData["image"]}`;
          }
        }
        productsById[doc.id] = productData;
        businessProductId[productData["business_product_id"]] = 1;
        productsById[doc.id] = Helper.resetCartItem(productData);
      });
      this.setState({ "productsById": productsById, "productsReadyDiv": "block", "productsLoading": false });
    });
  }



  componentDidMount() {
    Helper.openLoginPage();
    userId = localStorage.getItem("currentUserId");
    const querySnapshot = firebaseApp.findAllProducts(window.productLength);
    this.fetchAllProducts(querySnapshot);

    currentProjectId = firebaseApp.findProjectId();

    const categoryQuerySnapshot = firebaseApp.findAllCategories(10);
    this.fetchAllCategories(categoryQuerySnapshot);
  }

  openStorePage = (e, storeId) => {
    const { history } = this.props;
    if (history) history.push(`stores/${storeId}`);
  }



  removeFromCart = (e, productId) => {
    let productsById = this.state.productsById;
    let selectedProduct = productsById[productId];
    console.log(selectedProduct);
    firebaseApp.deleteUserCartById(selectedProduct["cart_id"]);
    selectedProduct = Helper.resetCartItem(selectedProduct);
    delete selectedProduct["cart_id"];
    productsById[productId] = selectedProduct;
    this.setState({ "productsById": productsById, "cartTotal": Helper.getCartTotal(productsById) });
  }

  tabsClicked = (cagegoryId) => {
    if ((cagegoryId == null) || (cagegoryId.length == 0)) return;
    const querySnapshot = firebaseApp.findAllProductsByCategory(cagegoryId, window.productLength);
    this.fetchAllProducts(querySnapshot);
  };



  render() {
    return (
      <div>
        <Layout style={{ backgroundColor: "#FFFFFF", minHeight: "100vh" }}>
          <Header style={{position: 'fixed', zIndex: 1, width: '100%', backgroundColor:"#003333", paddingLeft: 0 }}>
            <SideMenu />
          </Header>

          <Skeleton loading={this.state.productsLoading} active>
            <Tabs onChange={(e) => this.tabsClicked(e)} style={{ height: 48, marginLeft: 10, paddingBottom: 0, marginRight: 10 }}>
              {Object.values(this.state.categoriesById).map((category, i) => (
                <TabPane tab={category.name} key={category.id} />
              ))}
            </Tabs>


            <Content style={{ backgroundColor: "#FFFFFF" }}>
              {Object.values(this.state.productsById).map((product, i) => (
                <Card hoverable style={{ marginTop: 0, marginBotton: 0 }} key={`product-${i}`}>
                  {(product["image"] == undefined) ? (
                    <div key={"without-image-"+product.id}>
                      <div><Title style={{color:"#663300"}} level={5}>{product.product_name}</Title></div>
                      {
                        (product["order_unit"] > 0) ? (
                          <div key={"with-quantity-"+product.id}>
                            <div><p><Text>Price: {product.price}, Unit: {product.unit}, Qty:&#160;{product["order_unit"]}, Amt: <span style={window.priceCss}>₹{product["total"]}</span></Text></p></div>
                            <div>
                              <Space size={10}>
                                <InputNumber type="number" min={0} max={100} style={{ width: 50 }} id={product.id} defaultValue={product["order_unit"]} value={product["order_unit"]} onChange={(e) => this.unitChanged(e, product.id)} />
                                <Button style={{backgroundColor:"#ff9900"}} onClick={(e) => this.addToCart(e, product.id)} icon={<i className="fas fa-plus"></i>} size={5} ></Button>
                                <Button onClick={(e) => this.reduceToCart(e, product.id)} icon={<i className="fas fa-minus"></i>} size={5} ></Button>
                                <Button style={{ color: '#FF4500', borderColor: '#FF4500' }} onClick={(e) => this.removeFromCart(e, product.id)} icon={<i className="fa fa-trash-alt"></i>} size={5}></Button>
                              </Space>
                            </div>
                            <div style={{ marginTop: 10 }}>
                              <Button style={{ marginLeft: 0, paddingLeft: 0 }} type="link" onClick={(e) => this.openStorePage(e, product["store_id"])} size={5}>Store</Button>
                              <Button style={{ marginLeft: 0, paddingLeft: 0 }} type="link" onClick={(e) => this.openCartPage()} size={5}>Cart</Button>
                            </div>
                          </div>
                        ) : (
                          <div key={"without-quantity-"+product.id}>
                            <div><p><Text>Price: {product.price}, Unit: {product.unit}</Text></p></div>
                            <div>
                              <Space size={10}>
                                <Button style={{backgroundColor:"#ff9900"}} onClick={(e) => this.addToCart(e, product.id)} icon={<i className="fas fa-cart-plus"></i>} size={5}>&#8194;Add to Cart</Button>
                                <Button onClick={(e) => this.onProductBookmarkClicked(e, product.id)} icon={<i className="fa fa-bookmark" />}></Button>
                                <Button style={{ marginLeft: 0, paddingLeft: 0 }} type="link" onClick={(e) => this.openStorePage(e, product["store_id"])} size={5}>Store</Button>
                              </Space>
                            </div>
                          </div>
                        )
                      }
                    </div>
                  ) : (
                    <div key={"with-image-"+product.id}>
                      <Space align="top" size={12}>
                        <div>
                          <div><Image width={100} src={product.image} /></div>
                          <div><Button style={{ marginLeft: 0, paddingLeft: 0 }} type="link" onClick={(e) => this.openStorePage(e, product["store_id"])} size={5}>Store</Button>
                          { (product["order_unit"] > 0) && (<Button style={{ marginLeft: 0, paddingLeft: 0 }} type="link" onClick={(e) => this.openCartPage()} size={5}>Cart</Button> )}
                          </div>
                        </div>
                        <div>
                          <div><Title style={{color:"#663300"}} level={5}>{product.product_name}</Title></div>
                          {
                            (product["order_unit"] > 0) ? (
                              <div key={"with-quantity-"+product.id}>
                                <div><p><Text>Price: {product.price}, Unit: {product.unit}, Qty:&#160;{product["order_unit"]}, Amt: <span style={window.priceCss}>₹{product["total"]}</span></Text></p></div>
                                <div>
                                  <Space size={10}>
                                    <InputNumber type="number" min={0} max={100} style={{ width: 50 }} id={product.id} defaultValue={product["order_unit"]} value={product["order_unit"]} onChange={(e) => this.unitChanged(e, product.id)} />
                                    <Button style={{backgroundColor:"#ff9900"}} onClick={(e) => this.addToCart(e, product.id)} icon={<i className="fas fa-plus"></i>} size={5} ></Button>
                                    <Button onClick={(e) => this.reduceToCart(e, product.id)} icon={<i className="fas fa-minus"></i>} size={5} ></Button>
                                    <Button style={{ color: '#FF4500', borderColor: '#FF4500' }} onClick={(e) => this.removeFromCart(e, product.id)} icon={<i className="fa fa-trash-alt"></i>} size={5}></Button>
                                  </Space>
                                </div>
                              </div>
                            ) : (
                              <div key={"without-quantity-"+product.id}>
                                <div><p><Text>Price: {product.price}, Unit: {product.unit}</Text></p></div>
                                <div>
                                  <Space size={10}>
                                    <Button style={{backgroundColor:"#ff9900"}}  onClick={(e) => this.addToCart(e, product.id)} icon={<i className="fas fa-cart-plus"></i>} size={5}>&#8194;Add to Cart</Button>
                                    <Button onClick={(e) => this.onProductBookmarkClicked(e, product.id)} icon={<i className="fa fa-bookmark" />}></Button>
                                  </Space>
                                </div>
                              </div>
                            )
                          }
                        </div>
                      </Space>
                    </div>
                  )
                  }
                </Card>
              ))}

              <Card hoverable style={{ marginTop: 50, display: this.state.productsReadyDiv }}>
                <Divider className="divider">Cost Details</Divider>
                <div><Title level={5}>Total amount: <span style={window.totalCostCss}>₹{this.state.cartTotal}</span></Title></div>
                <div><Text type="secondary">Please note that we don't collect money. You can pay on delivery.</Text></div>
              </Card>

            </Content>
          </Skeleton>
          <CartwinksFooter />
        </Layout>
      </div>
    );
  }

}

export default AllProducts;
