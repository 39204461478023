import { initializeApp } from 'firebase/app';
import { getFirestore, collection, query, where, getDocs, getDoc, doc, setDoc, orderBy, limit, updateDoc, deleteDoc, documentId, serverTimestamp } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from "firebase/auth";

const settings = {timestampsInSnapshots: true};

var testFirebaseConfig = {
  apiKey: "AIzaSyDNVdN67q0ymFhyFY7YwT-qEOAelv1ZKn8",
  authDomain: "testcartwinks-com.firebaseapp.com",
  projectId: "testcartwinks-com",
  storageBucket: "testcartwinks-com.appspot.com",
  messagingSenderId: "531758343424",
  appId: "1:531758343424:web:202a84adb3f0dab1bcffe5",
  measurementId: "G-D1MSHKP84Y"
};

const prodFirebaseConfig = {
  apiKey: "AIzaSyCNg0HYw6dxBw0GBs-X3R3aY1gM5t74sUk",
  authDomain: "cartwinks-com.firebaseapp.com",
  projectId: "cartwinks-com",
  storageBucket: "cartwinks-com.appspot.com",
  messagingSenderId: "512208945928",
  appId: "1:512208945928:web:2f6f66d1341ab99e1ca2bd",
  measurementId: "G-7HH2GM49H0"
};

// Initialize Firebase
const currentAppConfig = prodFirebaseConfig;
const currentProjectId = currentAppConfig["projectId"];
const firebaseApplication = initializeApp(currentAppConfig);
const firestoreApp = getFirestore(firebaseApplication);
const firebaseAuthApp = getAuth(firebaseApplication);
//firebase.firestore().settings(settings);


const userCartRef = collection(firestoreApp, "user_carts");
const productsRef = collection(firestoreApp, "products");
const categoriesRef = collection(firestoreApp, "categories");
const storesRef = collection(firestoreApp, "stores");
const userStoresRef = collection(firestoreApp, "user_stores");
const userOrdersRef = collection(firestoreApp, "user_orders");
const storeUsersRef = collection(firestoreApp, "store_users");
const storeCategoriesRef = collection(firestoreApp, "store_categories");
const userAddressRef = collection(firestoreApp, "user_address");
const userConversionsRef = collection(firestoreApp, "user_conversions");
const userProductBookmarkRef = collection(firestoreApp, "user_product_bookmarks");
    


export default class firebaseApp {

  constructor() {
    console.log("Firebase initialized");
  }

  static findProjectId()
  {
    return currentProjectId;
  }

  static findUserCartItemByProductIdAndOrderId = async(userId, productId, orderId) => {
    const firebaseQuery = query(userCartRef, where("user_id", '==', userId), where("product_id", '==', productId), where("order_id", '==', orderId));
    return await getDocs(firebaseQuery);
  }

  //firebase.firestore().collection("user_carts").doc(selectedProduct["cart_id"]).delete();
  static deleteUserCartById = async(cartId) => {
    await deleteDoc(doc(firestoreApp, "user_carts", cartId));
  }



  static findAllProducts = async(recordLength) => {
    const firebaseQuery = query(productsRef, where("show","==",1), orderBy("ord"), limit(recordLength));
    return await getDocs(firebaseQuery);
  }

  static findBookmarkProducts = async(userId) => {
    const firebaseQuery = query(userProductBookmarkRef, where("user_id","==",userId), orderBy("created_at"), limit(10));
    return await getDocs(firebaseQuery);
  }

  static findAllProductsByCategory = async(categoryId, recordLength) => {
    console.log(categoryId);
    const firebaseQuery = query(productsRef, where("category_id", "array-contains", categoryId), where("show","==",1), orderBy("ord"), limit(recordLength));
    return await getDocs(firebaseQuery);
  }
  

  static findAllCategories = async(recordLength) => {
    const firebaseQuery = query(categoriesRef, where("show","==",1), orderBy("ord"), limit(recordLength));
    return await getDocs(firebaseQuery);
  }

  static findStoreCategories = async(storeId, recordLength) => {
    const firebaseQuery = query(storeCategoriesRef, where("show","==",storeId), where("show","==",1), orderBy("ord"), limit(recordLength));
    return await getDocs(firebaseQuery);
  }


  static findStoreCategoryByStoreId = async(storeId) => {
    const firebaseQuery = query(doc(firestoreApp, "store_categories", storeId));
    return await getDoc(firebaseQuery);
  }

  
  static findProductsByStoreIdAndCategoryId = async(storeId, categoryId, length) => {
    const firebaseQuery = query(productsRef, where("store_id","==",storeId), where("category_id", "array-contains", categoryId), where("show","==",1), orderBy("ord"), limit(length));
    return await getDocs(firebaseQuery);
  }

  static findProductsByStoreId = async(storeId) => {
    const firebaseQuery = query(productsRef, where("store_id","==",storeId), where("show","==",1), orderBy("ord"), limit(100));
    return await getDocs(firebaseQuery);
  }

  static findProductsByIdIn = async(findProductsByIdsIn) => {
    if(findProductsByIdsIn.length == 0 ) { return []; }
    const firebaseQuery = query(productsRef, where(documentId(),"in",findProductsByIdsIn));
    return await getDocs(firebaseQuery);
  }

  static findAllStores = async(recordLength) => {
    const firebaseQuery = query(storesRef, where("show","==",1), orderBy("ord"), limit(recordLength));
    return await getDocs(firebaseQuery);
  }
  
  
  static findUserCartDetailsByOrderId = async(userId, orderId) => {
    const firebaseQuery = query(userCartRef, where("user_id","==", userId), where("order_id", "==", orderId), limit(100));
    return await getDocs(firebaseQuery);
  }

  static findCartDetailsByOrderId = async(orderId) => {
    const firebaseQuery = query(userCartRef, where("order_id", "==", orderId), limit(100));
    return await getDocs(firebaseQuery);
  }


  static findUserCartByUserIdAndProductIdAndOrderId = async(userId, findProductsById, orderId) => {
    const firebaseQuery = query(userCartRef, where("user_id", '==', userId), where("product_id","==",findProductsById), where("order_id","==",orderId));
    return await getDocs(firebaseQuery);
  }



    
  static findUserStoresByUserId = async(userId) => {
    const firebaseQuery = query(userStoresRef, where("user_id","==", userId), limit(100));
    return await getDocs(firebaseQuery);
  }

  static findOwnStoresByUserId = async(userId) => {
    console.log(userId);
    const firebaseQuery = query(storeUsersRef, where("user_id","==",userId), limit(10));
    return await getDocs(firebaseQuery);
  }
  

  static findStoreById = async(storeId) => {
    const firebaseQuery = query(doc(firestoreApp, "stores", storeId));
    return await getDoc(firebaseQuery);
  }

  static findStoreOrderByStoreId = async(storeId) => {
    const firebaseQuery = query(userOrdersRef, where("store_id","==",storeId), limit(100));
    return await getDocs(firebaseQuery);
  }

  static findStoresByPincode = async(pincode) => {
    const firebaseQuery = query(storesRef, where("pincode","==",pincode), limit(100));
    return await getDocs(firebaseQuery);
  }

  static findStoresByPincodeAndAreaName = async(pincode, areaName) => {
    const firebaseQuery = query(storesRef, where("pincode","==",pincode), where("area_name","==",areaName), limit(100));
    return await getDocs(firebaseQuery);
  }

  static findStoresByAreaName = async(areaName) => {
    const firebaseQuery = query(storesRef, where("area_name","==",areaName), limit(100));
    return await getDocs(firebaseQuery);
  }

  

  static findUserOrderByUserId = async(userId) => {
    const firebaseQuery = query(userOrdersRef, where("user_id","==",userId), orderBy("created_at", "desc"), limit(100));
    return await getDocs(firebaseQuery);
  }
  
  
  static findStoresByIdIn = async(storeIds) => {
    console.log("storeIds",storeIds);
    const firebaseQuery = query(storesRef, where(documentId(),"in",storeIds), limit(100));
    return await getDocs(firebaseQuery);
  }

  static findUserAddressByIds = async(addressIds) => {
    if(addressIds.length==0) return [];
    const firebaseQuery = query(userAddressRef, where(documentId(),"in",addressIds), limit(100));
    return await getDocs(firebaseQuery);
  }

  static findStores = async() => {
    const firebaseQuery = query(storesRef, where("show","==",1), orderBy("store_name"), limit(1000));
    return await getDocs(firebaseQuery);
  }


  static findServerTime = () => {
    return serverTimestamp();
  }

  static findUserCartsByOrderIdIn = async(userId, orderIds) => {
    const firebaseQuery = query(userCartRef, where("user_id", '==', userId), where("order_id","in",orderIds), orderBy("created_at", "desc"), limit(1000));
    return await getDocs(firebaseQuery);
  }

  static getCartById = async(cartId) => {
    const firebaseQuery = query(doc(firestoreApp, "user_carts", cartId));
    return await getDoc(firebaseQuery);
  }

  static updateCartById = async(docId, docData) => {
    await updateDoc(doc(firestoreApp, "user_carts", docId), docData); 
  }

  static saveUserCarts = async(docId, docData) => {
    await setDoc(doc(firestoreApp, "user_carts", docId), docData); 
  }

  static saveUserAddress = async(docId, docData) => {
    await setDoc(doc(firestoreApp, "user_address", docId), docData); 
  }

  

  static updateUserCartsByStore = async(docId, docData) => {
    console.log(docId);
    console.log(docData);
    //await updateDoc(doc(firestoreApp, "user_carts", docId), docData); 
  }


  static saveUserOrder = async(docId, docData) => {
    await setDoc(doc(firestoreApp, "user_orders", docId), docData); 
  }

  static updateUserOrder = async(docId, docData) => {
    await updateDoc(doc(firestoreApp, "user_orders", docId), docData); 
  }

  static updateUserOrderStatus = async(docId, docData) => {
    await updateDoc(doc(firestoreApp, "user_order_status", docId), docData); 
  }

  static saveUserOrderStatus = async(docId, docData) => {
    await setDoc(doc(firestoreApp, "user_order_status", docId), docData); 
  }

  static saveUserStores = async(docId, docData) => {
    await setDoc(doc(firestoreApp, "user_stores", docId), docData); 
  }


  static bookmarkProductByUser = async(docId, docData) => {
    await setDoc(doc(firestoreApp, "user_product_bookmarks", docId), docData); 
  }
  static removeBookmarkProductByUser = async(docId) => {
    await deleteDoc(doc(firestoreApp, "user_product_bookmarks", docId));
  }
  

  static updateUserStores = async(docId, docData) => {
    await setDoc(doc(firestoreApp, "user_stores", docId), docData); 
  }
  static deleteUserStoreById = async(storeId) => {
    await deleteDoc(doc(firestoreApp, "user_stores", storeId));
  }

  static findUserAddress = async(userId) => {
    const firebaseQuery = query(userAddressRef, where("user_id","==",userId), limit(100));
    return await getDocs(firebaseQuery);
  }

  static deleteUserAddressById = async(addressId) => {
    await deleteDoc(doc(firestoreApp, "user_address", addressId));
  }

  static assignAnonymousUserWithAuthUser = async(anonymousUserId, authUserId) => {
    await setDoc(doc(userConversionsRef), {"anonymous_user_id": anonymousUserId, "auth_user_id": authUserId, "created_at": serverTimestamp()}); 
  }
  


}