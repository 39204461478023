import React from "react";
import firebaseApp from "./Firebase";
import SideMenu from "./SideMenu";
import { Space, Row, Col, Card, Typography, Layout, Button } from "antd";
import Helper from "./Helper";
import CartwinksFooter from "./CartwinksFooter"

const { Title } = Typography;
const { Header, Content } = Layout;
let userId = null;

class UserStores extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      storesById: {},
      noStoresMessage: 'block'
    }
  }




  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };




  onStoreShoppingClicked = (e, storeId) => {
    console.log(storeId);
    const storeObj = this.state.storesById[storeId];
    window.location.href = "stores/" + storeObj["store_id"];
  };
  


  onStoreUnBookmarkClicked =(e, storeId) => {
    const docId = userId + "_" + storeId;
    //firebase.firestore().collection("user_stores").doc(docId).delete();
    firebaseApp.deleteUserStoreById(docId);
    const storesById = this.state.storesById;
    console.log(docId);
    delete storesById[storeId];
    this.setState({ "storesById": storesById });
  };


  storePageClicked = (e) => {
    window.location.href = `stores`;
  }

  onUserFavoriteStoreFetch = (queryPromise) => {
    const storesById = [];
    queryPromise.then((querySnapshot) => {
    querySnapshot.forEach((doc) => {
        storesById[doc.id] = doc.data();
        storesById[doc.id]["id"] = doc.id;
    });
    this.setState({ "storesById": storesById });
    });
  }



  onCollectionUpdate = (queryPromise) => {
    const storeIds = [];
    queryPromise.then((querySnapshot) => {
    querySnapshot.forEach((doc) => {
        const { store_id } = doc.data();
        console.log(doc.data());
        if(storeIds.indexOf(store_id) !== -1){ return; }
        storeIds.push(store_id);
    });

    if(storeIds.length===0) return;

    this.setState( {'noStoresMessage': 'none'} );
    var queryPromise= firebaseApp.findStoresByIdIn(storeIds);
    this.onUserFavoriteStoreFetch(queryPromise);
  });
  };

  componentDidMount() {
    Helper.openLoginPage();
    userId = localStorage.getItem("currentUserId");
    var queryPromise= firebaseApp.findUserStoresByUserId(userId);
    this.onCollectionUpdate(queryPromise);
  }

  render() {
    return (
      <div>
          <Layout style={{ backgroundColor: "#FFFFFF", minHeight: "100vh" }}>
            <Header style={{position: 'fixed', zIndex: 1, width: '100%', backgroundColor:"#003333", paddingLeft: 0 }}>
                <SideMenu/>
            </Header>

            <Content>
            { Object.values(this.state.storesById).map((store, i) => (
              <Card hoverable style={{ marginTop:50 }} key={"store_"+i} cover={<img alt="example" src={store["banner_image"]} />} >
                <center>
                  <Title className="storeName" level={3}>{store.store_name}</Title>
                  <Title level={5}>{store.address} {store.location} {store.city}. PH:&#160;<a href={"tel://" + store.phone}>{store.phone}</a></Title>
                  <Space size={10} style={{marginTop:20}}>
                    <Button type="primary" id={store.id} onClick={(e) => this.onStoreUnBookmarkClicked(e, store.id)} icon={<i className="fa fa-bookmark"/>}>&#x2003;Unfavourite</Button>
                    <Button type="primary" id={store.id} onClick={(e) => this.onStoreShoppingClicked(e, store.id)} icon={<i className="fa fa-shopping-bag"/>}>&#x2003;Shopping</Button>
                  </Space>
                </center>
                
            </Card>
            ))}
            </Content>

            <Row style={{display:this.state.noStoresMessage}}>
              <Col md={{span: 12, offset: 6}}>
                  <Card style={{textAlign: 'center'}}>
                      <h1 style={{ color: '#52c41a' }}>You haven't marked any store as your favorite.</h1>
                  <Title level={3}><Button type="primary" onClick={(e) => this.storePageClicked(e)}>Visit stores now</Button></Title>
                  </Card>
                </Col>
            </Row>
            <CartwinksFooter/>
        </Layout>
      </div>
    );
  }

}

export default UserStores;
