import React from "react";
import logo from "./logo.jpeg";
import firebaseApp from "./Firebase";
import SideMenu from "./SideMenu";
import { Select, Divider, Card, Table, Typography, InputNumber, Input, Layout, Menu, Drawer, Button, Radio, Space } from "antd";
import Helper from "./Helper"
import CartwinksFooter from "./CartwinksFooter"

const { SubMenu } = Menu;
const { Title, Text } = Typography;
const { Header, Footer, Sider, Content } = Layout;
const { Meta } = Card;
const { Option } = Select;

let preferredDelivery = 0;
let checkoutHappened = 0;
let userCartsById = {};
let userCartsByProductId = {};
let urlRoute = {};
let userId = null;


class StoreCart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      productsById: {},
      productLaterById: {},
      productRemovedById: {},
      placement: "left",
      collapsed: false,
      orderPlaced: 'none',
      cartForLater: 'none',
      cartForNow: 'none',
      cartRemoved: 'none',
      cartTotal: 0,
      noCartItemsMessage: "block"
    }
  }

  deliveryOptionChangedByStore = (deliveryOption, userOrderId) => {
    console.log(deliveryOption, userOrderId);
    deliveryOption = (deliveryOption * 1);
    firebaseApp.updateUserOrder(userOrderId, { "delivery": deliveryOption, "status_at": firebaseApp.findServerTime() });
    //firebase.firestore().collection("user_orders").doc(userOrderId).update({"delivery": deliveryOption, "status_at": firebaseApp.findServerTime() });
  }

  deliveryStatusChangedByStore = (deliveryStatus, userOrderId) => {
    deliveryStatus = (deliveryStatus * 1);
    firebaseApp.updateUserOrder(userOrderId, { "status": deliveryStatus, "status_at": firebaseApp.findServerTime() });
    //firebase.firestore().collection("user_orders").doc(userOrderId).update({"status": deliveryStatus, "status_at": firebaseApp.findServerTime() });
    const orderStatusDoc = userOrderId + "_" + new Date();
    firebaseApp.updateUserOrderStatus(orderStatusDoc, { "status": deliveryStatus, "created_at": firebaseApp.findServerTime(), "user_id": userId });
    //firebase.firestore().collection("user_order_status").doc(orderStatusDoc).set({"status": deliveryStatus, "created_at": firebaseApp.findServerTime(), "user_id": userId });
  }

  onBlur = () => {
    console.log('blur');
  }
  onFocus = () => {
    console.log('focus');
  }
  onSearch = (val) => {
    console.log('search:', val);
  }





  unitChanged = (value, productId) => {
    const totalUnits = Helper.getInteger(value);
    const productsById = this.state.productsById;
    var selectedProduct = productsById[productId];
    selectedProduct["order_unit"] = totalUnits;
    this.reworkCartDetails(selectedProduct);
  }








  processOrderByStoreXXXX = () => {
    const orderId = userId + "_" + new Date().getTime();
    const productsById = this.state.productsById;
    const cartItemsCount = Object.keys(productsById).length;
    if (cartItemsCount == 0) { return; }

    firebaseApp.saveUserOrder(orderId, { "store_user_id": userId, "status": 0, "items": cartItemsCount, "order_id": orderId, "created_at": firebaseApp.findServerTime(), "delivery": preferredDelivery, "total": Helper.getCartTotal(productsById) });
    //firebase.firestore().collection("user_orders").doc(orderId).set({"store_user_id":userId, "status":0, "items":cartItemsCount, "order_id": orderId, "created_at":firebaseApp.findServerTime(), "delivery": preferredDelivery, "total": Helper.getCartTotal(productsById)});
    for (var productId in productsById) {
      const cartId = productsById[productId]["cart_id"];
      //firebase.firestore().collection("user_carts").doc(cartId).update({"order_id": orderId, "cart_at":firebaseApp.findServerTime()});
      firebaseApp.updateCartById(cartId, { "order_id": orderId, "cart_at": firebaseApp.findServerTime() });
    }
    checkoutHappened = 1;
    this.setState({ "orderPlaced": "block", "cartForNow": "none", "cartForLater": "none" });
  }





  saveCartForLater = (e, cartId) => {
    firebaseApp.updateCartById(cartId, { "store_order_id": 1 });
    //firebase.firestore().collection("user_carts").doc(cartId).update({"store_order_id": 1});
    const productsById = this.state.productsById;
    const productLaterById = this.state.productLaterById;
    for (var item in productsById) {
      if (productsById[item]["cart_id"] == cartId) {
        productLaterById[item] = productsById[item];
        delete productsById[item];
      }
    }
    const cartTotal = Helper.getCartTotal(productsById);
    let cartForNow = "block";
    if (cartTotal == 0) {
      cartForNow = "none";
    }

    this.setState({ "productsById": productsById, "productLaterById": productLaterById, "cartForLater": "block", "cartForNow": cartForNow, "cartTotal": Helper.getCartTotal(productsById) });
  }



  saveCartForNowFromRemoved = (e, cartId) => {
    firebaseApp.updateCartById(cartId, { "store_order_id": 0 });
    //firebase.firestore().collection("user_carts").doc(cartId).update({"store_order_id": 0});
    const productRemovedById = this.state.productRemovedById;
    const productsById = this.state.productsById;
    for (var item in productRemovedById) {
      if (productRemovedById[item]["cart_id"] == cartId) {
        productsById[item] = productRemovedById[item];
        delete productRemovedById[item];
      }
    }
    let cartRemoved = "block";
    if (Object.keys(productRemovedById).length == 0) {
      cartRemoved = "none";
    }
    this.setState({ "productsById": productsById, "productRemovedById": productRemovedById, "cartRemoved": cartRemoved, "cartForNow": "block", "cartTotal": Helper.getCartTotal(productsById) });
  }



  saveCartForNow = (e, cartId) => {
    firebaseApp.updateCartById(cartId, { "store_order_id": 0 });
    //firebase.firestore().collection("user_carts").doc(cartId).update({"store_order_id": 0});
    const productLaterById = this.state.productLaterById;
    const productsById = this.state.productsById;
    for (var item in productLaterById) {
      if (productLaterById[item]["cart_id"] == cartId) {
        productsById[item] = productLaterById[item];
        delete productLaterById[item];
      }
    }
    let cartForLater = "block";
    if (Object.keys(productLaterById).length == 0) {
      cartForLater = "none";
    }
    this.setState({ "productsById": productsById, "productLaterById": productLaterById, "cartForLater": cartForLater, "cartForNow": "block", "cartTotal": Helper.getCartTotal(productsById) });
  }






  removeFromCartByStore = (e, cartId) => {
    const productsById = this.state.productsById;
    firebaseApp.updateUserOrder(cartId, { "store_order_id": 10, "store_removed_at": firebaseApp.findServerTime() });
    //firebase.firestore().collection("user_carts").doc(cartId).update({"store_order_id":10, "store_removed_at":firebaseApp.findServerTime()});
    for (var item in productsById) {
      if (productsById[item]["cart_id"] == cartId) {
        delete productsById[item];
      }
    }

    let [cartForNow, noCartItemsMessage] = "block";
    if (Object.keys(productsById).length == 0) {
      cartForNow = "none";
      noCartItemsMessage = "block";
    }
    console.log(noCartItemsMessage);
    this.setState({ "productsById": productsById, "noCartItemsMessage": noCartItemsMessage, "cartForNow": cartForNow, "cartTotal": Helper.getCartTotal(productsById) });
  }




  removeFromCartLaterByStore = (e, cartId) => {
    firebaseApp.updateUserOrder(cartId, { "store_order_id": 10, "store_removed_at": firebaseApp.findServerTime() });
    //firebase.firestore().collection("user_carts").doc(cartId).update({"store_order_id":10, "store_removed_at":firebaseApp.findServerTime()});
    const productLaterById = this.state.productLaterById;
    for (var item in productLaterById) {
      if (productLaterById[item]["cart_id"] == cartId) {
        delete productLaterById[item];
      }
    }

    let cartForLater = "block";
    if (Object.keys(productLaterById).length == 0) {
      cartForLater = "none";
    }
    this.setState({ "productLaterById": productLaterById, "cartForLater": cartForLater });
  }







  displayUserCartItemsByStore = (queryPromise) => {
    const productsById = {};
    const productLaterById = {};
    const productRemovedById = {};

    let [cartForNow, cartForLater, cartRemoved] = ["none", "none", "none"];
    queryPromise.then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        const eachProduct = doc.data();
        const eachCartProduct = userCartsByProductId[doc.id];
        if (eachCartProduct == undefined) { return; }
        console.log(eachCartProduct);
        eachProduct["id"] = doc.id;
        eachProduct["cart_id"] = eachCartProduct["cart_id"];
        eachProduct["order_unit"] = eachCartProduct["order_unit"];
        eachProduct["store_order_unit"] = ((eachCartProduct["store_order_unit"]) ? eachCartProduct["store_order_unit"] : eachCartProduct["order_unit"]);
        eachProduct["total"] = (eachProduct["order_unit"] * eachProduct["price"]);
        eachProduct["store_total"] = (eachProduct["store_order_unit"] * eachCartProduct["price"]);

        eachCartProduct["store_order_id"] = ((eachCartProduct["store_order_id"]) ? eachCartProduct["store_order_id"] : 0);

        if (eachCartProduct["store_order_id"] == 0)//for now
        {
          productsById[doc.id] = eachProduct;
          cartForNow = "block";
        }
        else if (eachCartProduct["store_order_id"] == 10)//deleted
        {
          productRemovedById[doc.id] = eachProduct;
          cartRemoved = "block";
        }
        else //for later
        {
          productLaterById[doc.id] = eachProduct;
          cartForLater = "block";
        }
      });
      this.setState({ "productRemovedById": productRemovedById, "cartRemoved": cartRemoved, "noCartItemsMessage": "none", "productsById": productsById, "productLaterById": productLaterById, "cartForLater": cartForLater, "cartForNow": cartForNow, "cartTotal": Helper.getCartTotal(productsById) });
    });
  }




  addToCartByStore = (e, productId) => {
    var selectedProduct = this.state.productsById[productId];
    selectedProduct["store_user_id"] = userId;
    selectedProduct["product_id"] = productId;
    selectedProduct["store_order_unit"] = Helper.getInteger(selectedProduct["store_order_unit"]);
    selectedProduct["store_order_unit"] = (selectedProduct["store_order_unit"] + 1);
    this.updateCartDetails(selectedProduct);
  }

  reduceToCartByStore = (e, productId) => {
    var selectedProduct = this.state.productsById[productId];
    selectedProduct["store_user_id"] = userId;
    selectedProduct["product_id"] = productId;
    selectedProduct["store_order_unit"] = Helper.getInteger(selectedProduct["store_order_unit"]);
    selectedProduct["store_order_unit"] = (selectedProduct["store_order_unit"] - 1);
    this.updateCartDetails(selectedProduct);
  }



  reworkCartDetails = (selectedProduct) => {
    let cartId = userId + "_" + new Date().getTime();
    const productId = selectedProduct["product_id"];


    var queryPromise = firebaseApp.findUserCartByUserIdAndProductIdAndOrderId(userId, productId, 0);
    queryPromise.then((querySnapshot) => {
      if (querySnapshot.size == 0) {
        console.log("not exits", userId, productId, 0);
        selectedProduct["cart_id"] = cartId;
        this.updateCartDetails(selectedProduct);
      }
      querySnapshot.forEach((doc) => {
        console.log("The Product is already in cart ", doc.data());
        selectedProduct["cart_id"] = doc.id;
        this.updateCartDetails(selectedProduct);
      });
    }).catch((error) => {
      console.log("Error getting documents: ", error);
      selectedProduct["cart_id"] = cartId;
      this.updateCartDetails(selectedProduct);
    });
  }




  updateCartDetails = (selectedProduct) => {
    selectedProduct["store_total"] = (selectedProduct["store_order_unit"] * selectedProduct["price"]);
    selectedProduct["store_order_id"] = 0;
    selectedProduct["sotre_modified_at"] = firebaseApp.findServerTime();
    firebaseApp.updateUserCartsByStore(selectedProduct["cart_id"], selectedProduct);
    const productsById = this.state.productsById;
    console.log(selectedProduct);
    productsById[selectedProduct.id] = selectedProduct;
    this.setState({ "productsById": productsById, "cartTotal": Helper.getCartTotal(productsById) });
  }



  findUserCartDetailsByStore = (queryPromise) => {
    const productIds = [];
    queryPromise.then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        //console.log(doc.data());
        const { product_id } = doc.data();
        productIds.push(product_id);
        userCartsByProductId[product_id] = doc.data();
        userCartsById[doc.id] = doc.data();
      });

      const uniqProductIds = [...new Set(productIds)];
      console.log(uniqProductIds);

      this.setState({ "noCartItemsMessage": "display" });
      if (uniqProductIds.length == 0) { return; }


      //firebase supports max of 10
      Helper.sliceBySize(uniqProductIds, 10).forEach((eachSet) => {
        var queryPromise = firebaseApp.findProductsByIdIn(eachSet);
        this.displayUserCartItemsByStore(queryPromise);
      });

    });


  }



  componentDidMount() {
    Helper.openLoginPage();
    checkoutHappened = 0;
    urlRoute = Helper.getUrlRoute(window.location.pathname);
    userId = localStorage.getItem("currentUserId");
    var queryPromise = firebaseApp.findCartDetailsByOrderId(urlRoute["storeorder"]);
    this.findUserCartDetailsByStore(queryPromise);
  }


  render() {
    return (
      <div>
        <Layout style={{ backgroundColor: "#FFFFFF", minHeight: "100vh" }}>
          <Header style={{position: 'fixed', zIndex: 1, width: '100%', backgroundColor:"#003333", paddingLeft: 0 }}>
            <SideMenu />
          </Header>
          <div style={{ display: this.state.cartForNow, border: '2px solid teal', paddingLeft: 20, paddingRight: 20, marginBottom: 20 }}>
            <Divider  className="divider">Cart Items (By Store)</Divider>
            {Object.values(this.state.productsById).map((product, i) => (
              <Card hoverable style={{ marginTop: 50 }} key={`products-${i}`} >
                <Title level={5}>{product.product_name}</Title>
                <p><Text type="secondary">Price: {product.price}, Unit: {product.unit}, CR: {product["order_unit"]} <br />
                  Unit: {product["store_order_unit"]}, Amount: <span style={window.priceCss}>₹{product["total"]}</span></Text></p>
                <div>
                  <Space size={20}>
                    <InputNumber type="number" min={1} max={10} style={{ width: 50 }} id={product.id} value={product["order_unit"]} defaultValue={product["order_unit"]} onChange={(e) => this.unitChanged(e, product["cart_id"])} />
                    <Button onClick={(e) => this.addToCartByStore(e, product.id)} icon={<i className="fas fa-plus"></i>} size={5} ></Button>
                    <Button onClick={(e) => this.reduceToCartByStore(e, product.id)} icon={<i className="fas fa-minus"></i>} size={5} ></Button>
                    <Button onClick={(e) => this.removeFromCartByStore(e, product["cart_id"])} icon={<i className="fa fa-trash" />} size={5}></Button>
                    <Button onClick={(e) => this.saveCartForLater(e, product["cart_id"])} icon={<i className="fa fa-cart-arrow-down"></i>} size={5} ></Button>
                  </Space>
                </div>
              </Card>
            ))}

            <Divider  className="divider">Cost Details</Divider>
            <div style={{ paddingLeft: '15px', paddingRight: '15px' }}>
              <div><Title level={5}>Total amount: <span style={window.totalCostCss}>₹{this.state.cartTotal}</span></Title></div>

              <Divider  className="divider">Delivery Details</Divider>
              <div><Title level={5}>Option &#x2003;
                <Select showSearch defaultValue="Door Delivery" style={{ display: 'inline-block', width: 200 }} optionFilterProp="children"
                  onChange={(e) => this.deliveryOptionChangedByStore(e, urlRoute["storeorder"])} onFocus={this.onFocus} onBlur={this.onBlur} onSearch={this.onSearch}
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                  <Option value="0">Door Delivery</Option>
                  <Option value="1">I will pickup from the store</Option>
                </Select>
              </Title>
              </div>

              <div><Title level={5}>Status &#x2003;
                <Select showSearch defaultValue="1" style={{ display: 'inline-block', width: 200 }}
                  optionFilterProp="children" onChange={(e) => this.deliveryStatusChangedByStore(e, urlRoute["storeorder"])}>
                  <Option value="0">Packaging</Option>
                  <Option value="1">In Transit</Option>
                  <Option value="10">Delivered</Option>
                </Select>
              </Title>
              </div>
            </div>

            {
              /*
              <Divider  className="divider"><Button type="primary" onClick={(e) => this.processOrderByStore(e)}  icon={<i className="fa fa-shopping-bag"/>} size={5} >&#x2003;Proceed to Packaging</Button></Divider>
              */
            }
          </div>

          <div style={{ display: this.state.cartForLater, border: '2px solid teal', paddingLeft: 20, paddingRight: 20 }}>
            <Divider  className="divider">Cart for Later (By Store)</Divider>
            {Object.values(this.state.productLaterById).map((product, i) => (
              <div style={{ paddingLeft: '15px', paddingRight: '15px' }}>
                <div><Title level={5}>{product.product_name}</Title></div>
                <p><Text type="secondary">Price: {product.price}, Unit: {product.unit}</Text></p>
                <p>
                  <Space size={10}>
                    <Input type="number" min={1} max={10} style={{ width: 75 }} id={product.id} defaultValue={1} onChange={(e) => this.unitChanged(e, product["cart_id"])} />
                    <Button onClick={(e) => this.removeFromCartLaterByStore(e, product["cart_id"])} icon={<i className="fa fa-trash" />} size={5} ></Button>
                    <Button onClick={(e) => this.saveCartForNow(e, product["cart_id"])} icon={<i className="fas fa-cart-plus"></i>} size={5} ></Button>
                  </Space>
                </p>
              </div>
            ))}
          </div>


          <div style={{ display: this.state.cartRemoved, border: '2px solid teal', paddingLeft: 20, paddingRight: 20 }}>
            <Divider  className="divider">Removed from Cart (By Store)</Divider>
            {Object.values(this.state.productRemovedById).map((product, i) => (
              <Card key={`removed-${i}`} size="small" title={product.product_name} style={{ marginBottom: 20 }} extra={<a href="#">More</a>}>
                <p><Text type="secondary">Price: {product.price}, Unit: {product.unit}</Text></p>
                <p>
                  <Space size={10}>
                    <Input type="number" min={1} max={10} style={{ width: 75 }} id={product.id} defaultValue={1} onChange={(e) => this.unitChanged(e, product["cart_id"])} />
                    <Button onClick={(e) => this.saveCartForNowFromRemoved(e, product["cart_id"])} icon={<i className="fas fa-cart-plus"></i>} size={5} ></Button>
                  </Space>
                </p>
              </Card>
            ))}
          </div>



          <Content style={{ backgroundColor: "#FFFFFF" }} style={{ display: this.state.orderPlaced }}>
            <Divider  className="divider">Thank you</Divider>
            <Card hoverable style={{ marginTop: 50 }}>
              <div><Title level={5}>Your order has been placed successfully.</Title></div>
              <p><Text type="secondary">Store will contact you directly</Text></p>
            </Card>
          </Content>

          <Content style={{ backgroundColor: "#FFFFFF" }} style={{ display: this.state.noCartItemsMessage }}>
            <Divider  className="divider">You have no items in your cart</Divider>
            <Card hoverable style={{ marginTop: 50 }}>
              <div><Title level={5}>Place your order now!.</Title></div>
            </Card>
          </Content>

          <CartwinksFooter/>
        </Layout>
      </div>
    );
  }

}

export default StoreCart;