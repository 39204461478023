import React from "react";
import logo from "./logo.jpeg";
import firebase from "./Firebase";
import SideMenu from "./SideMenu";
import { Modal, Skeleton, Select, Divider, Card, Table, Typography, InputNumber, Input, Layout, Menu, Drawer, Button, Radio, Space, Row, Col } from "antd";
import Helper from "./Helper"
import firebaseApp from "./Firebase";
import { v4 as uuidv4 } from 'uuid';
import CartwinksFooter from "./CartwinksFooter"

let currentProjectId = null;
const { SubMenu } = Menu;
const { Title, Text } = Typography;
const { Header, Footer, Sider, Content } = Layout;
const { Meta } = Card;
const { Option } = Select;
let userId = null;

const additionalValues = {"delivery_type_id":0};
let checkoutHappened = 0;
let userCartsByProductId = {};
let totalByStore = [];
let cartTotal = 0;
let cartLaterTotal = 0;
let orderId = "";

const ccavenueMerchantId = 693244;
let ccavenueAccessCode = "AVQD54IJ10BU16DQUB";
let ccavenueWorkingKey = "91FE41EBE2A79B6C95065519FFD8A4F2";

const nodeCCAvenue = require('node-ccavenue');
let ccavConfig = new nodeCCAvenue.Configure({ "merchant_id": ccavenueMerchantId, "working_key": ccavenueWorkingKey });
let ccAvenueParams = { order_id: "ORD8765432", currency: 'INR', redirect_url: encodeURIComponent("https://asia-east2-cartwinks-com.cloudfunctions.net/handle-ccavenue-response"), cancel_url: encodeURIComponent("https://asia-east2-cartwinks-com.cloudfunctions.net/handle-ccavenue-response") };

let cartForLaterCount = 0;
let cartForNowCount = 0;

class Cart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userAddressesById: {},
      userAddresses:[],
      productsById: {},
      productLaterById: {},
      visible: false,
      placement: "left",
      collapsed: false,
      orderPlaced: 'none',
      cartForLater: 'none',
      cartForNow: 'none',
      cartTotal: 0,
      cartLaterTotal: 0,
      noCartItemsMessage: "none",
      noCartItemsMessageBoolean: true,
      isUserInfoVisible: false
    }
  }




  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  deliveryOptionChanged = (val) => {
    additionalValues["delivery_type_id"] = val;
  }
  deliveryAddressOptionChanged = (val) => {
    if(val=="ADD_NEW_ADDRESS")
    {
      delete additionalValues["address_id"];
      window.location.href = "/login";
      return;
    }
    additionalValues["address_id"] = val;
  }

  onBlur = () => {
    console.log('blur');
  }
  onFocus = () => {
    console.log('focus');
  }
  onSearch = (val) => {
    console.log('search:', val);
  }

  displayUserAddress = (queryPromise) => {
    const userAddressesById = {};
    const userAddresses = [];
    userAddresses.push(<Option key="ADD_NEW_ADDRESS">Add new address</Option>);
    queryPromise.then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        userAddressesById[doc.id] = doc.data();
        userAddressesById[doc.id]["id"] = doc.id;
        userAddresses.push(<Option key={doc.id}>{userAddressesById[doc.id]["name"] + ", " + userAddressesById[doc.id]["address_1"]}</Option>);
      });
      this.setState({ "userAddressesById": userAddressesById, "userAddresses":userAddresses });
      console.log(userAddressesById);
    });
  };

  pullUserAddress = (e) => {
    const currentUserAnonymous = (localStorage.getItem("currentUserAnonymous") + "").toLowerCase();
    if (currentUserAnonymous == "true") {
      return;
    }
    const currentUserId = localStorage.getItem("currentUserId");
    console.log(currentUserId);
    var queryPromise = firebaseApp.findUserAddress(currentUserId);
    this.displayUserAddress(queryPromise);
  }


  unitChanged = (value, productId) => {
    const totalUnits = Helper.getInteger(value);
    const productsById = this.state.productsById;
    var selectedProduct = productsById[productId];
    console.log(selectedProduct);
    selectedProduct["order_unit"] = totalUnits;
    this.reworkCartDetails(selectedProduct);
  }


  userInfoModalOk = (e) =>
  {
    this.setState({"isUserInfoVisible":false});
  }

  processOrderByUser = (e, payNow) => {

    if(additionalValues["address_id"]==undefined)
    {
      this.setState({"isUserInfoVisible":true})
      return;
    }

    orderId = uuidv4();

    const productsById = this.state.productsById;
    const cartItemsCount = Object.keys(productsById).length;
    if (cartItemsCount == 0) { return; }


    //it requires the order to be created for each store based on the collective products
    let uniqueStoreIds = {};
    for (var productId in productsById) {
      let eachProduct = productsById[productId];
      const storeId = eachProduct["store_id"];
      if (uniqueStoreIds[storeId] == undefined) {
        uniqueStoreIds[storeId] = { "items": 0, "total": 0 };
      }
      uniqueStoreIds[storeId]["order_id"] = orderId + "-" + storeId;
      uniqueStoreIds[storeId]["parent_order_id"] = orderId;
      uniqueStoreIds[storeId]["items"] = (uniqueStoreIds[storeId]["items"] + 1);
      uniqueStoreIds[storeId]["total"] = (uniqueStoreIds[storeId]["total"] + eachProduct["total"]);

      eachProduct["order_id"] = uniqueStoreIds[storeId]["order_id"];
      productsById[productId] = eachProduct;
    }

    ccAvenueParams["amount"] = cartTotal;
    ccAvenueParams["order_id"] = orderId;
    const encryptedOrderData = ccavConfig.getEncryptedOrder(ccAvenueParams);
    let cartwinks_payment_link = "https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction&merchant_id=" + ccavenueMerchantId + "&access_code=" + ccavenueAccessCode + "&encRequest=" + encryptedOrderData;
    localStorage.setItem("cartwinks_payment_link", cartwinks_payment_link);

    for (var storeId in uniqueStoreIds) {
      const eachStoreOrder = uniqueStoreIds[storeId];
      var storeOrderDetails = { "total":cartTotal, "payment_status":0, "cartwinks_payment_link": cartwinks_payment_link, "store_id": storeId, "user_id": userId, "status": 0, "items": eachStoreOrder["items"], "order_id": eachStoreOrder["order_id"], "created_at": firebaseApp.findServerTime() };
      storeOrderDetails = {...storeOrderDetails, ...additionalValues};
      
      firebaseApp.saveUserOrder(eachStoreOrder["order_id"], storeOrderDetails);
    }

    for (var productId in productsById) {
      let eachProduct = productsById[productId];
      console.log(eachProduct);
      firebaseApp.updateCartById(eachProduct["cart_id"], { "order_id": eachProduct["order_id"], "cart_at": firebaseApp.findServerTime() });
    }


    checkoutHappened = 1;
    this.setState({ "orderPlaced": "block", "cartForNow": "none", "cartForLater": "none" });

    if (payNow == 0) return;
    window.location.href = cartwinks_payment_link;
  }



  saveCartForLater = (e, cartId) => {
    firebaseApp.updateCartById(cartId, { "order_id": 1 });
    const productsById = this.state.productsById;
    const productLaterById = this.state.productLaterById;
    for (var item in productsById) {
      if (productsById[item]["cart_id"] == cartId) {
        console.log("Matching");
        productLaterById[item] = productsById[item];
        delete productsById[item];
        break;
      }
    }

    cartTotal = Helper.getCartTotal(productsById);
    totalByStore = Helper.getCartTotalByStoreId(productsById);
    cartLaterTotal = Helper.getCartTotal(productLaterById);

    let cartForNow = "block";
    if (cartTotal == 0) {
      cartForNow = "none";
    }

    this.setState({ "cartLaterTotal": cartLaterTotal, "productsById": productsById, "productLaterById": productLaterById, "cartForLater": "block", "cartForNow": cartForNow, "cartTotal": cartTotal });
  }



  saveCartForNow = (e, cartId) => {
    firebaseApp.updateCartById(cartId, { "order_id": 0 });
    const productLaterById = this.state.productLaterById;
    const productsById = this.state.productsById;
    for (var item in productLaterById) {
      if (productLaterById[item]["cart_id"] == cartId) {
        productsById[item] = productLaterById[item];
        delete productLaterById[item];
        break;
      }
    }

    let cartForLater = "block";
    cartTotal = Helper.getCartTotal(productsById);
    cartLaterTotal = Helper.getCartTotal(productLaterById);

    if (cartLaterTotal == 0) {
      cartForLater = "none";
    }
    totalByStore = Helper.getCartTotalByStoreId(productsById);
    this.setState({ "cartLaterTotal": cartLaterTotal, "productsById": productsById, "productLaterById": productLaterById, "cartForLater": cartForLater, "cartForNow": "block", "cartTotal": cartTotal });
  }


  removeFromCart = (e, cartId) => {
    const productsById = this.state.productsById;
    firebaseApp.deleteUserCartById(cartId);
    for (var item in productsById) {
      if (productsById[item]["cart_id"] == cartId) {
        delete productsById[item];
      }
    }

    let cartForNow = "block";
    let noCartItemsMessage = "none";
    if (Object.keys(productsById).length == 0) {
      cartForNow = "none";
      noCartItemsMessage = "block";
    }

    totalByStore = Helper.getCartTotalByStoreId(productsById);
    cartTotal = Helper.getCartTotal(productsById);

    this.setState({ "productsById": productsById, "noCartItemsMessageBoolean": false, "noCartItemsMessage": noCartItemsMessage, "cartForNow": cartForNow, "cartTotal": cartTotal });
  }





  removeFromCartLater = (e, cartId) => {
    firebaseApp.deleteUserCartById(cartId);
    const productLaterById = this.state.productLaterById;
    for (var item in productLaterById) {
      if (productLaterById[item]["cart_id"] == cartId) {
        delete productLaterById[item];
      }
    }

    let cartForLater = "block";
    if (Object.keys(productLaterById).length == 0) {
      cartForLater = "none";
    }
    const cartLaterTotal = Helper.getCartTotal(productLaterById);

    this.setState({ "productLaterById": productLaterById, "cartForLater": cartForLater, "cartLaterTotal": cartLaterTotal });
  };




  displayUserCartItems = (queryPromise) => {
    if (checkoutHappened == 1) { return; }

    const productsById = this.state.productsById;
    const productLaterById = this.state.productLaterById;

    queryPromise.then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        const eachProduct = doc.data();
        console.log("111", eachProduct);
        const eachCartProduct = userCartsByProductId[doc.id];
        if (eachCartProduct == undefined) { return; }
        eachProduct["id"] = doc.id;
        eachProduct["cart_id"] = eachCartProduct["cart_id"];
        eachProduct["order_unit"] = eachCartProduct["order_unit"];
        eachProduct["total"] = (eachProduct["order_unit"] * eachProduct["price"]);
        if (eachCartProduct["order_id"] == 0)//for now
        {
          console.log("for now", eachProduct);
          productsById[doc.id] = eachProduct;
          cartForNowCount++;
        }
        else //for later
        {
          console.log("for later", eachProduct);
          productLaterById[doc.id] = eachProduct;
          cartForLaterCount++;
        }
      });

      cartLaterTotal = Helper.getCartTotal(productLaterById);
      cartTotal = Helper.getCartTotal(productsById);

      let cartForLater = "block";
      let cartForNow = "block";
      let noCartItemsMessage = "none";
      let noCartItemsMessageBoolean = false;
      if (cartForNowCount == 0) cartForNow = "none";
      if (cartForLaterCount == 0) cartForLater = "none";
      if ((cartForNowCount == 0) && (cartForLaterCount == 0)) {
        noCartItemsMessage = "block";
        noCartItemsMessageBoolean = false;
      }
      console.log(noCartItemsMessageBoolean)

      totalByStore = Helper.getCartTotalByStoreId(productsById);
      this.setState({ "cartLaterTotal": cartLaterTotal, "noCartItemsMessageBoolean": noCartItemsMessageBoolean, "noCartItemsMessage": noCartItemsMessage, "productsById": productsById, "productLaterById": productLaterById, "cartForLater": cartForLater, "cartForNow": cartForNow, "cartTotal": cartTotal });
    });
  }



  addToCart = (e, productId) => {
    var selectedProduct = this.state.productsById[productId];
    selectedProduct["user_id"] = userId;
    selectedProduct["product_id"] = productId;
    selectedProduct["product_name"] = selectedProduct["product_name"];
    selectedProduct["order_unit"] = Helper.getInteger(selectedProduct["order_unit"]);
    selectedProduct["order_unit"] = selectedProduct["order_unit"] + 1;
    console.log(selectedProduct);
    this.reworkCartDetails(selectedProduct);
  }
  
  reduceToCart = (e, productId) => {
    var selectedProduct = this.state.productsById[productId];
    selectedProduct["user_id"] = userId;
    selectedProduct["product_id"] = productId;
    selectedProduct["product_name"] = selectedProduct["product_name"];
    selectedProduct["order_unit"] = Helper.getInteger(selectedProduct["order_unit"]);
    selectedProduct["order_unit"] = selectedProduct["order_unit"] - 1;
    console.log(selectedProduct);
    this.reworkCartDetails(selectedProduct);
    if(selectedProduct["order_unit"]<=0) { 
      this.removeFromCart(e, selectedProduct["cart_id"]);
    }
  }



  reworkCartDetails = (selectedProduct) => {
    let cartId = selectedProduct["cart_id"];
    this.updateCartDetails(selectedProduct);
  }




  openStorePage = (e, storeId) => {
    const { history } = this.props;
    if (history) history.push(`stores/${storeId}`);
  };



  updateCartDetails = (selectedProduct) => {
    selectedProduct["total"] = (selectedProduct["order_unit"] * selectedProduct["price"]);
    selectedProduct["order_id"] = 0;
    selectedProduct["created_at"] = firebaseApp.findServerTime();
    firebaseApp.saveUserCarts(selectedProduct["cart_id"], selectedProduct);

    const productsById = this.state.productsById;
    console.log(selectedProduct);
    productsById[selectedProduct.id] = selectedProduct;

    totalByStore = Helper.getCartTotalByStoreId(productsById);
    cartTotal = Helper.getCartTotal(productsById);

    this.setState({ "productsById": productsById, "cartTotal": cartTotal });
  }



  findUserCartDetails = (queryPromise) => {
    const productIds = [];
    queryPromise.then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        const { product_id } = doc.data();
        if (product_id == undefined) { return; }
        productIds.push(product_id);
        userCartsByProductId[product_id] = doc.data();
        userCartsByProductId[product_id]["cart_id"] = doc.id;
      });

      const uniqProductIds = [...new Set(productIds)];
      console.log(uniqProductIds);

      this.setState({ "noCartItemsMessage": "block", "noCartItemsMessageBoolean": false });
      if (uniqProductIds.length == 0) { return; }
      userId = localStorage.getItem("currentUserId");

      cartForNowCount = 0;
      cartForLaterCount = 0;

      this.setState({ "productsById": {}, "productLaterById": {} });

      //firebase supports max of 10
      Helper.sliceBySize(uniqProductIds, 10).forEach((eachSet) => {
        const queryPromiseCart = firebaseApp.findProductsByIdIn(eachSet);
        this.displayUserCartItems(queryPromiseCart);
      });
    });
  }

  storePageClicked = (e) => {
    window.location.href = `stores`;
  }

  ordersPageClicked = (e) => {
    window.location.href = `orders`;
  }

  goLoginPage = (e) => {
    window.location.href = `login`;
  }

  componentDidMount() {
    Helper.openLoginPage();
    currentProjectId = firebaseApp.findProjectId();
    checkoutHappened = 0;
    userId = localStorage.getItem("currentUserId");
    console.log(userId);
    var queryPromise = firebaseApp.findUserCartsByOrderIdIn(userId, [0, 1]);
    this.findUserCartDetails(queryPromise);

    this.pullUserAddress();


    if (currentProjectId != "cartwinks-com")
    {
      ccavenueAccessCode = "AVYX54IJ11BV32XYVB";
      ccavenueWorkingKey = "967A14BE63462B4A226A0830F66C8545";
      ccavConfig = new nodeCCAvenue.Configure({ "merchant_id": ccavenueMerchantId, "working_key": ccavenueWorkingKey });
      ccAvenueParams["redirect_url"] = encodeURIComponent("https://asia-east2-testcartwinks-com.cloudfunctions.net/handle-ccavenue-response");
      ccAvenueParams["cancel_url"] = encodeURIComponent("https://asia-east2-testcartwinks-com.cloudfunctions.net/handle-ccavenue-response");
    }

  }


  render() {
    return (
      <div>
        <Layout style={{ backgroundColor: "#FFFFFF", minHeight: "100vh" }}>
          <Header style={{position: 'fixed', zIndex: 1, width: '100%', backgroundColor:"#003333", paddingLeft: 0 }}>
            <SideMenu />
          </Header>
          <Skeleton loading={this.state.noCartItemsMessageBoolean} active avatar>
            <div style={{ display: this.state.cartForNow, border: '2px solid teal', paddingLeft: 20, paddingRight: 20, paddingBottom: 20, marginTop: 20 }}>
              <Divider className="divider">Cart - <span>{Object.keys(this.state.productsById).length}</span> Items, <span>₹{this.state.cartTotal}</span> Value</Divider>
              {Object.values(this.state.productsById).map((product, i) => (
                <Card hoverable style={{ marginTop: 0 }} key={"getnow_" + i}>
                  <Title style={{color:"#663300"}} level={5}>{product.product_name}</Title>
                  <p><Text>Price: {product.price}, Unit: {product.unit}, <br />
                    Quantity: <span style={window.quantity}>{product["order_unit"]}</span>, Amount: <span style={window.priceCss}>₹{product["total"]}</span></Text></p>
                  <div>
                    <Space size={10}>
                      <InputNumber type="number" min={1} max={10} style={{ width: 50 }} id={product.id} value={product["order_unit"]} defaultValue={product.order_unit} onChange={(e) => this.unitChanged(e, product.id)} />
                      <Button style={{ paddingLeft: 10, paddingRight: 10 }} onClick={(e) => this.addToCart(e, product.id)} icon={<i className="fas fa-plus"></i>} size={5} ></Button>
                      <Button style={{ paddingLeft: 10, paddingRight: 10 }} onClick={(e) => this.reduceToCart(e, product.id)} icon={<i className="fas fa-minus"></i>} size={5} ></Button>
                      <Button style={{ paddingLeft: 10, paddingRight: 10 }} onClick={(e) => this.removeFromCart(e, product["cart_id"])} icon={<i className="fa fa-trash" />} size={5}></Button>
                      <Button onClick={(e) => this.saveCartForLater(e, product["cart_id"])} icon={<i className="fas fa-cart-arrow-down"></i>} size={5}></Button>
                    </Space>
                  </div>
                  <div><Button style={{ marginLeft: 0, paddingLeft: 0 }} type="link" onClick={(e) => this.openStorePage(e, product["store_id"])} size={5}>Go to Store</Button></div>
                </Card>
              ))}
            </div>

            <div style={{ display: this.state.cartForNow, border: '2px solid teal', paddingLeft: 20, paddingRight: 20, marginTop: 20 }}>
              <Divider className="divider">Cost Details</Divider>
              <div style={{ paddingLeft: '15px', paddingRight: '15px' }}>
                <div><Title level={5}>Total amount <span style={window.totalCostCss}>₹{this.state.cartTotal}</span></Title></div>
                <div><Row justify="space-around">
                  <Col span={6}><Title level={5}>Delivery</Title></Col>
                  <Col span={18}><Select showSearch
                    defaultValue="Door Delivery" style={{ display: 'inline-block', width: 200 }} optionFilterProp="children"
                    onChange={this.deliveryOptionChanged} onFocus={this.onFocus} onBlur={this.onBlur} onSearch={this.onSearch}
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                    <Option value="1">Door Delivery</Option>
                    <Option value="2">I will pickup from the store</Option>
                  </Select></Col>
                </Row>
                  <Row justify="space-around" style={{marginTop:"10px"}}>
                    <Col span={6}><Title level={5}>Deliver At</Title></Col>
                    <Col span={18}><Select showSearch style={{ display: 'inline-block', width: 200 }} optionFilterProp="children"
                      onChange={this.deliveryAddressOptionChanged} onFocus={this.onFocus} onBlur={this.onBlur} onSearch={this.onSearch}
                      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                      {this.state.userAddresses}
                    </Select></Col>
                  </Row>
                </div>
                
                {(Object.keys(totalByStore).length != 1) &&
                  <div style={{marginTop:"10px"}}><Text type="secondary">Order will be generated for each store separately.</Text></div>
                }

                <div id="store_wise_total" style={{marginTop:10}}>
                  <Table size="small" rowKey={record => record.store_id} pagination={false} dataSource={totalByStore} columns={[{
                    title: 'Store Id',
                    dataIndex: 'name',
                    key: 'name',
                    render: (value, row, index, key) => { return <div><Button style={{ marginLeft: 0, paddingLeft: 0 }} type="link" onClick={(e) => this.openStorePage(e, row["store_id"])} size={5}><span style={{ "textTransform": "uppercase" }}>{value}</span> </Button> </div> }
                  }, {
                    title: 'Total',
                    dataIndex: 'total',
                    key: 'total',
                  }, {
                    title: 'Items',
                    dataIndex: 'items',
                    key: 'items',
                  }]} />
                </div>
              </div>

              {(localStorage.getItem("currentUserAnonymous") != "true") && <Divider><Space size={10}><Button type="primary" onClick={(e) => this.processOrderByUser(e, 0)} icon={<i className="fa fa-shopping-bag" />} size={5} >&#x2003;Pay on Delivery</Button> <Button type="primary" onClick={(e) => this.processOrderByUser(e, 1)} icon={<i className="fas fa-money-bill"></i>} size={5} >&#x2003;Pay Now</Button></Space></Divider>}
              {(localStorage.getItem("currentUserAnonymous") == "true") && <Divider><Space size={10}><Button type="primary" onClick={(e) => this.goLoginPage()} icon={<i className="far fa-user"></i>} size={5} >&#x2003;Login to complete</Button></Space></Divider>}
            </div>


            <div style={{ display: this.state.cartForLater, border: '2px solid teal', paddingLeft: 20, paddingRight: 20, marginTop: 20 }}>
              <Divider className="divider">Cart for Later <span>₹{this.state.cartLaterTotal}</span></Divider>
              {Object.values(this.state.productLaterById).map((product, i) => (
                <div style={{ paddingLeft: '15px', paddingRight: '15px' }} key={"forlater_" + i}>
                  <div><Title level={5}>{product.product_name}</Title></div>
                  <div><Text type="secondary">Price: {product.price}, Unit: {product.unit}, Amt: <span style={window.priceCss}>₹{product["total"]}</span></Text></div>
                  <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                    <Space size={10}>
                      <Button onClick={(e) => this.removeFromCartLater(e, product["cart_id"])} icon={<i className="fa fa-trash" />} size={5} ></Button>
                      <Button onClick={(e) => this.saveCartForNow(e, product["cart_id"])} icon={<i className="fas fa-cart-plus"></i>} size={5} ></Button>
                    </Space>
                  </div>
                </div>
              ))}
            </div>


            <Content style={{ backgroundColor: "#FFFFFF", display: this.state.orderPlaced, marginTop: 20 }}>
              <Divider className="divider">Thank you</Divider>
              <Card hoverable style={{ marginTop: 50 }} key="message">
                <center>
                  <div><Title level={5}>Your order has been placed successfully.</Title></div>
                  <p><Text type="secondary">Store will contact you directly.</Text></p>
                  <Title level={3}><Button type="primary" onClick={(e) => this.ordersPageClicked(e)}>Check Placed Orders</Button></Title>
                </center>
              </Card>
            </Content>

            <Row style={{ marginTop: 50, display: this.state.noCartItemsMessage }}>
              <Col md={{ span: 12, offset: 6 }}>
                <Card style={{ textAlign: 'center' }} key="submit-order">
                  <h1 style={{ color: '#52c41a' }}>You have no items in your cart</h1>
                  <Title level={3}><Button type="primary" onClick={(e) => this.storePageClicked(e)}>Place your order now!</Button></Title>
                </Card>
              </Col>
            </Row>
          </Skeleton>

          <Modal title="User Info" visible={this.state.isUserInfoVisible} onOk={this.userInfoModalOk} onCancel={this.componentWillUnmountuserInfoModalOk}>
            <p>Please select the delivery address.</p>
          </Modal>      

        </Layout>
        <CartwinksFooter />
      </div>
    );
  }

}

export default Cart;
