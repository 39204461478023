import React from "react";
import firebaseApp from "./Firebase";
import SideMenu from "./SideMenu";
import { Divider, Space, Row, Col, Card, Typography, Input, Layout, Button } from "antd";
import Helper from "./Helper";
import CartwinksFooter from "./CartwinksFooter"
import { FacebookShareButton, TelegramShareButton, TwitterShareButton, WhatsappShareButton} from "react-share";


const { Title, Text } = Typography;
const { Header, Content } = Layout;
const { Search } = Input;

let userInput = { "area_name": "", "pincode": "" };
let userId = null;

class Stores extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      "userSelection": {},
      items: [],
      text: "",
      storesById: {},
      visible: false,
      placement: "left",
      collapsed: false,
    }
  }


  onStoreShoppingClicked = (e, storeId) => {
    storeId = storeId.toLowerCase();
    window.location.href = `stores/${storeId}`;
  }



  onStoreBookmarkClicked = (e, storeId) => {
    const userId = localStorage.getItem("currentUserId");
    const storesById = this.state.storesById;
    const storeObj = storesById[storeId];
    const docId = userId + "_" + storeObj["store_id"]
    storeObj["bookmark"] = 1;
    storesById[storeId] = storeObj;
    console.log(storeObj);
    this.setState({ "storesById": storesById });
    firebaseApp.saveUserStores(docId, { "user_id": userId, "store_id": storeId, "business_id": storeObj["business_id"], "created_at": firebaseApp.findServerTime() });
  }




  onStoreUnBookmarkClicked = (e, storeId) => {
    const docId = userId + "_" + storeId;
    firebaseApp.deleteUserStoreById(docId);
    const storesById = this.state.storesById;
    const storeObj = storesById[storeId];
    storeObj["bookmark"] = 0;
    console.log(storeObj);
    storesById[storeId] = storeObj;
    this.setState({ "storesById": storesById });
  }

  onSearch = () => {

  }

  onStoreDetailFetch = (queryPromise) => {
    const storesById = [];
    queryPromise.then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        let storeData = doc.data();
        if(storeData["area_name"]!==undefined) storeData["area_name"] = storeData["area_name"] + ",";
        if(storeData["address"]!==undefined) storeData["address"] = storeData["address"] + ",";
        if(storeData["city"]!==undefined) storeData["city"] = storeData["city"] + ".";
        storesById[doc.id] = storeData;
        storesById[doc.id]["id"] = doc.id;
        storesById[doc.id]["bookmark"] = 0;
      });
      this.setState({ "storesById": storesById });
    });
  }

  storeFilterByPincode = (pincode) => {
    userInput["pincode"] = pincode.trim();
    console.log(userInput);
    if (userInput["pincode"].length == 0) { return; }
    var queryPromise = firebaseApp.findStoresByPincode(userInput["pincode"]);
    this.onStoreDetailFetch(queryPromise);
  }

  storeFilterByAreaname = (areaName) => {
    userInput["area_name"] = areaName.trim();
    console.log(userInput);
    var queryPromise = null;
    if (userInput["area_name"].length == 0) { return; }
    if (userInput["pincode"].length == 0)
    { 
      queryPromise = firebaseApp.findStoresByAreaName(userInput["area_name"]);
    }
    else
    {
      queryPromise = firebaseApp.findStoresByPincodeAndAreaName(userInput["pincode"], userInput["area_name"]);
    }
    this.onStoreDetailFetch(queryPromise);
  }


  componentDidMount() {
    Helper.openLoginPage();
    var queryPromise = firebaseApp.findStores();
    this.onStoreDetailFetch(queryPromise);
  }




  render() {
    return (
      <div>
        <Layout style={{ backgroundColor: "#FFFFFF", minHeight: "100vh" }}>
          <Header style={{position: 'fixed', zIndex: 1, width: '100%', backgroundColor:"#003333", paddingLeft: 0 }}>
            <SideMenu />
          </Header>

          <Content style={{ backgroundColor: "#FFFFFF" }}>

            <div style={{ paddingLeft: 20, paddingRight: 20, marginTop: 20, marginBottom: 20 }}>
              <div><Search placeholder="Pincode" onSearch={(e) => this.storeFilterByPincode(e)} size="large" /></div>
              <div style={{ marginTop: 20 }}><Search placeholder="Area name" onSearch={(e) => this.storeFilterByAreaname(e)} size="large" /></div>
            </div>


            {Object.values(this.state.storesById).map((store, i) => (
              <Card key={i} hoverable style={{ marginLeft: 10, marginRight: 10, marginBottom: 30, maxWidth: '100%', border: '1px solid black' }} cover={<img alt="example" src={store["banner_image"]} />}>
                <center>
                  <Title className="storeName" level={3}>{store.store_name}</Title>
                  <Title level={5}>{store.address} {store.location} {store.city}. PH:&#160;<a href={"tel://" + store.phone}>{store.phone}</a></Title>
                </center>

                <div>
                <center>
                  {(store.bookmark == 0) ?
                    <div key="with-bookmark">
                    <Space size={10}>
                      <Button type="primary" onClick={(e) => this.onStoreBookmarkClicked(e, store.id)} icon={<i className="fas fa-bookmark" />}></Button>
                      <Button type="primary" onClick={(e) => this.onStoreShoppingClicked(e, store.id)} icon={<i className="fa fa-shopping-bag" />}></Button>
                      <div type="primary" style={{padding:"5px 10px 5px 10px", borderRadius:"3px", backgroundColor:"#5898fc"}}><WhatsappShareButton url={window.location}><i className="fab fa-whatsapp"></i></WhatsappShareButton></div>
                      <div type="primary" style={{padding:"5px 10px 5px 10px", borderRadius:"3px", backgroundColor:"#5898fc"}}><FacebookShareButton url={window.location}><i className="fab fa-facebook-square"></i></FacebookShareButton></div>
                    </Space>
                    </div>
                    :
                    <div key="without-bookmark">
                    <Space size={10}>
                      <Button type="primary" style={{ backgroundColor: '#DC143C', borderColor: '#008000' }} onClick={(e) => this.onStoreUnBookmarkClicked(e, store.id)} icon={<i className="fa fa-trash-alt" />}></Button>
                      <Button type="primary" onClick={(e) => this.onStoreShoppingClicked(e, store.id)} icon={<i className="fa fa-shopping-bag" />}></Button>
                      <div type="primary" style={{padding:"5px 10px 5px 10px", borderRadius:"3px", backgroundColor:"#5898fc"}}><WhatsappShareButton url={window.location}><i className="fab fa-whatsapp"></i></WhatsappShareButton></div>
                      <div type="primary" style={{padding:"5px 10px 5px 10px", borderRadius:"3px", backgroundColor:"#5898fc"}}><FacebookShareButton url={window.location}><i className="fab fa-facebook-square"></i></FacebookShareButton></div>
                    </Space>
                    </div>
                  }
                  </center>
                </div>
              </Card>
            ))}
          </Content>




          {Object.keys(this.state.storesById).length == 0 ? (
            <Row style={{ marginTop: 50 }}>
              <Col md={{ span: 12, offset: 6 }}>
                <Card style={{ textAlign: 'center' }}>
                  <h1 style={{ color: '#52c41a' }}>We are sorry... There are no store are registered.</h1>
                </Card>

                <Divider  className="divider">How to bring my business online</Divider>
                <Card hoverable style={{ marginTop: 50 }}>
                  <Title level={5}>Your order has been placed successfully.</Title>
                  <p><Text type="secondary">Dear Patron, It is our greatest pleasure to see you hear. Yes, we can assit you to bring your business online, with no cost.</Text></p>
                  <p><Text type="secondary">Please contact us at <a href="tel://+91 93424 50052">+91 93424 50052</a> or email us at <a href="mailto:priya@cartwinks.com">priya@cartwinks.com</a>. It may take maximum of 2 hours to bring your business online.</Text></p>
                  <p><Text type="secondary">It doesn't matter you may sell Rose or Rocket. Your business is important for you, we can assist you to get your business online.</Text></p>
                  <Title level={5}>Please note that this software and services are fully free. Our executives will not ask any money. Do not make any payment whatever mentioned.</Title>
                </Card>

              </Col>
            </Row>
          ) : (<br />)}

          <CartwinksFooter/>
        </Layout>
      </div>
    );
  }

}

export default Stores;
